import React from 'react'
import loading_animation from 'assets/lottie/child-loader.json'
import { Col, Row } from 'reactstrap'
import Lottie from 'lottie-react'

const Loader = () => {
    return (
        <Row className='d-flex justify-content-center align-items-center w-100 h-100'>
            <div>
                <Lottie animationData={loading_animation} loop={true} />
            </div>
        </Row>
    )
}

export default Loader