import { useNotificationStore } from "hooks/Notification";
import { useEffect, useState } from "react";

// reactstrap components
import "assets/styles/notification/CustomerNotification.css";
import Common from "helpers/Common";
import { useCompanyStore } from "hooks/Company";
import "index.css";
import Select from "react-select";
import {
    Card,
    Col,
    Container,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { isEmpty } from "underscore";
import NotificationScheduleList from "./NotificationScheduleList";

// react icons
import CustomButton from "components/Button/CustomButton";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { FaEnvelope } from "react-icons/fa";
import { FaPaperclip, FaSpinner } from "react-icons/fa6";
import { IoLogoWhatsapp, IoMdCloseCircle } from "react-icons/io";
import { MdSms } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { notificationMessages } from "helpers/constantMessages";
import { getNotificationList } from "store/slice/Notification";
import Loader from "components/Loader/Loader";
import { useLoginStore } from "hooks/Login";


const NotificationSchedule = () => {
    const [notificationData, setNotificationData] = useState("");

    const [notificationType, setNotificationType] = useState(1);
    const [notificationPlatform, setNotificationPlatform] = useState(1);
    const [notificationValidate, setNotificationValidate] = useState({});
    const [customerEmail, setCustomerEmail] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [toEmail, setToEmail] = useState("");
    const [whatsAppNumber, setWhatsAppNumber] = useState("");
    const [SMSNumber, setSMSNumber] = useState("");

    const [
        ,
        { getNotificationById, sendEmail, sendWPSMS, sendSMS },
        { createNotification, updateNotification },
    ] = useNotificationStore();
    const [{ customerList }, { getCustomerByCompanyId }] = useCompanyStore();
    const [{}, {}, { setAlerts }] = useLoginStore();

    // Get Notification Data for Scheduled Notifications
    const notificationUpdateClick = async (notificationSettingID) => {
        setNotificationValidate({});
        let notificationsData = await getNotificationById({
            NotificationSettingID: notificationSettingID,
        });

        if (notificationsData && !isEmpty(notificationsData)) {
            let customerEmails = notificationsData[0]?.to.split(",");

            let selectedCustomer = [];
            if (customerList && customerList.length > 0) {
                selectedCustomer = customerList.filter((item) =>
                    customerEmails.includes(item.customerID.toString())
                );
            }

            let customerDropdownData = [];
            if (selectedCustomer && !isEmpty(selectedCustomer)) {
                selectedCustomer.map((v) => {
                    customerDropdownData.push({
                        value: v.customerID,
                        label: v.customerName,
                    });
                });
            }

            let previewData = notificationsData[0];
            setNotificationData({
                scheduleName: previewData?.scheduleName,
                customerEmail: customerDropdownData,
                frequency: previewData?.frequencyID,
                fromDate: Common.getFormatedDate(
                    previewData?.fromDate,
                    "YYYY-MM-DD",
                    true
                ),
                toDate: Common.getFormatedDate(
                    previewData?.toDate,
                    "YYYY-MM-DD",
                    true
                ),
                subject: previewData?.subject,
                body: previewData?.body,
                notificationSettingID: notificationSettingID,
                attachedFileName: previewData?.attachedFileName || "",
            });
            setCustomerEmail(customerDropdownData);
        }
    };

    const getCustomerList = () => {
        let custList = [];
        custList.push({
            value: "ALL",
            label: "ALL",
        });
        let list =
            customerList &&
            !isEmpty(customerList) > 0 &&
            customerList.map((prop, key) => {
                return {
                    value: prop.customerID,
                    label: prop.customerName + " (" + prop.phoneNumber + ")",
                };
            });
        custList = custList.concat(list);
        return custList;
    };

    const onCustomerChange = (selectedItems) => {
        // Check if the "Select All" option is selected
        if (
            selectedItems?.length > 0 &&
            selectedItems.some((item) => item.value === "ALL")
        ) {
            let filteredOptions = getCustomerList();
            setCustomerEmail(
                filteredOptions.filter((option) => option.value !== "ALL")
            );
        } else {
            setCustomerEmail(selectedItems);
        }

        let error = { ...notificationValidate };

        if (
            notificationType == 2 &&
            selectedItems &&
            selectedItems.length > 0
        ) {
            error.customerEmail = "has-success";
        } else {
            error.customerEmail = "has-danger";
        }
        setNotificationValidate(error);
    };

    const getFrequencyList = () => {
        return [
            { label: "Daily", value: 1 },
            { label: "Weekly", value: 2 },
            { label: "Week Day", value: 3 },
            { label: "Week Off", value: 4 },
            { label: "Monthly", value: 5 },
        ];
    };

    const uploadProduct = (e) => {
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
            alert("Please select image size lesser than 2MB.");
            e.target.value = "";
        } else {
            const file = event.target.files[0];
            let fileName =
                Common.getCompanyId() +
                "_" +
                Date.now() +
                "." +
                e.target.files[0].name.split(".")[1];
            setFile(new File([e.target.files[0]], fileName));
            setSelectedFile(e.target.files[0]);
        }
    };

    const removeImage = () => {
        setFile(null);
    };

    const handleSaveNotification = (e) => {
        if (notificationType == 1) {
            if (notificationPlatform == 1) {
                saveEmailNotification(e);
            } else if (notificationPlatform == 2) {
                saveWhatsAppNotification(e);
            } else if (notificationPlatform == 3) {
                saveSMSNotification(e);
            }
        } else {
            saveScheduledNotification(e);
        }
    };

    // Save function for Scheduled Notification
    const saveScheduledNotification = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        let error = false;
        let validate = { ...notificationValidate };
        if (!notificationData.notificationSettingID) {
            if (notificationValidate && isEmpty(notificationValidate)) {
                error = true;
                validate.scheduleName = "has-danger";
                validate.customerEmail = "has-danger";
                validate.frequency = "has-danger";
                validate.fromDate = "has-danger";
                validate.toDate = "has-danger";
                validate.subject = "has-danger";
                validate.body = "has-danger";
            } else {
                if (
                    !notificationValidate.scheduleName ||
                    notificationValidate.scheduleName === "has-danger"
                ) {
                    error = true;
                    validate.scheduleName = "has-danger";
                }
                if (
                    !notificationValidate.customerEmail ||
                    notificationValidate.customerEmail === "has-danger"
                ) {
                    error = true;
                    validate.customerEmail = "has-danger";
                }
                if (
                    !notificationValidate.frequency ||
                    notificationValidate.frequency === "has-danger"
                ) {
                    error = true;
                    validate.frequency = "has-danger";
                }
                if (
                    !notificationValidate.fromDate ||
                    notificationValidate.fromDate === "has-danger"
                ) {
                    error = true;
                    validate.fromDate = "has-danger";
                }
                if (
                    !notificationValidate.toDate ||
                    notificationValidate.toDate === "has-danger"
                ) {
                    error = true;
                    validate.toDate = "has-danger";
                }
                if (
                    !notificationValidate.subject ||
                    notificationValidate.subject === "has-danger"
                ) {
                    error = true;
                    validate.subject = "has-danger";
                }
                if (
                    !notificationValidate.body ||
                    notificationValidate.body === "has-danger"
                ) {
                    error = true;
                    validate.body = "has-danger";
                }
            }
        } else {
            if (notificationValidate.scheduleName === "has-danger") {
                error = true;
                validate.scheduleName = "has-danger";
            }
            if (notificationValidate.customerEmail === "has-danger") {
                error = true;
                validate.customerEmail = "has-danger";
            }
            if (notificationValidate.frequency === "has-danger") {
                error = true;
                validate.frequency = "has-danger";
            }
            if (notificationValidate.fromDate === "has-danger") {
                error = true;
                validate.fromDate = "has-danger";
            }
            if (notificationValidate.toDate === "has-danger") {
                error = true;
                validate.toDate = "has-danger";
            }
            if (notificationValidate.subject === "has-danger") {
                error = true;
                validate.subject = "has-danger";
            }
            if (notificationValidate.body === "has-danger") {
                error = true;
                validate.body = "has-danger";
            }
        }

        setNotificationValidate(validate);

        if (!error) {
            let formData = new FormData();
            let customerList = [];
            let isSelectedAll = false;
            customerEmail.map((v) => {
                customerList.push(v.value);
                if (v.value === "ALL") {
                    isSelectedAll = true;
                }
            });

            formData.append("NotificationTypeID", notificationPlatform);
            formData.append("ScheduleName", notificationData.scheduleName);
            formData.append("FrequencyID", notificationData.frequency);
            formData.append("FromDate", notificationData.fromDate);
            formData.append("ToDate", notificationData.toDate);
            formData.append("Subject", notificationData.subject);
            formData.append("Body", notificationData.body);
            formData.append("To", isSelectedAll ? "ALL" : customerList);

            if (selectedFile) {
                formData.append("attchedFile", selectedFile);
            }

            let res = "";
            if (notificationData.notificationSettingID) {
                formData.append(
                    "notificationSettingID",
                    notificationData.notificationSettingID
                );
                res = await updateNotification(formData);
            } else {
                res = await createNotification(formData);
            }

            if (res) {
                await setAlerts({
                    type: "success",
                    message: notificationMessages.notificationSCheduleSuccess,
                });
                await getNotificationList({
                    NotificationTypeID: notificationPlatform,
                });
            }
            resetScheduledNotificationForm(e);
        }
        setIsLoading(false);
    };

    // Save function for Broadcasted Email Notification
    const saveEmailNotification = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let error = false;
        let validate = { ...notificationValidate };

        if (notificationValidate && isEmpty(notificationValidate)) {
            error = true;
            validate.subject = "has-danger";
            validate.body = "has-danger";
            validate.emailTo = "has-danger";
        } else {
            if (
                !notificationValidate.subject ||
                notificationValidate.subject === "has-danger"
            ) {
                error = true;
                validate.subject = "has-danger";
            }
            if (
                !notificationValidate.body ||
                notificationValidate.body === "has-danger"
            ) {
                error = true;
                validate.body = "has-danger";
            }
            if (
                !notificationValidate.emailTo ||
                notificationValidate.emailTo === "has-danger"
            ) {
                error = true;
                validate.emailTo = "has-danger";
            }
        }

        setNotificationValidate(validate);

        if (!error) {
            let formData = new FormData();
            formData.append("EmailToAddress", toEmail);
            formData.append("EmailSubject", notificationData.subject);
            formData.append("EmailBody", notificationData.body);
            formData.append("files", selectedFile);
            let res = await sendEmail(formData);
            if (res) {
                await setAlerts({
                    type: "success",
                    message: notificationMessages.sendEMAILSuccess,
                });
            }

            resetScheduledNotificationForm(e);
        }
        setIsLoading(false);
    };

    // Save function for WhatsApp Notification
    const saveWhatsAppNotification = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let error = false;
        let validate = { ...notificationValidate };

        if (notificationValidate && isEmpty(notificationValidate)) {
            error = true;
            validate.subject = "has-danger";
            validate.body = "has-danger";
            validate.whatsAppNumber = "has-danger";
        } else {
            if (
                !notificationValidate.subject ||
                notificationValidate.subject === "has-danger"
            ) {
                error = true;
                validate.subject = "has-danger";
            }
            if (
                !notificationValidate.body ||
                notificationValidate.body === "has-danger"
            ) {
                error = true;
                validate.body = "has-danger";
            }
            if (
                !notificationValidate.whatsAppNumber ||
                notificationValidate.whatsAppNumber === "has-danger"
            ) {
                error = true;
                validate.whatsAppNumber = "has-danger";
            }
        }

        setNotificationValidate(validate);

        if (!error) {
            let param = {
                toMobileNumber: whatsAppNumber,
                subject: notificationData.subject,
                messageBody: notificationData.body,
                isHtml: "",
                templateName: "test",
            };
            let res = await sendWPSMS(param);
            if (res) {
                await setAlerts({
                    type: "success",
                    message: notificationMessages.sendSMSSuccess,
                });
                resetScheduledNotificationForm(e);
            }
        }
        setIsLoading(false);
    };

    // Save function for SMS Notification
    const saveSMSNotification = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let error = false;
        let validate = { ...notificationValidate };

        if (notificationValidate && isEmpty(notificationValidate)) {
            error = true;
            validate.subject = "has-danger";
            validate.body = "has-danger";
            validate.SMSNumber = "has-danger";
        } else {
            if (
                !notificationValidate.subject ||
                notificationValidate.subject === "has-danger"
            ) {
                error = true;
                validate.subject = "has-danger";
            }
            if (
                !notificationValidate.body ||
                notificationValidate.body === "has-danger"
            ) {
                error = true;
                validate.body = "has-danger";
            }
            if (
                !notificationValidate.SMSNumber ||
                notificationValidate.SMSNumber === "has-danger"
            ) {
                error = true;
                validate.SMSNumber = "has-danger";
            }
        }

        setNotificationValidate(validate);

        if (!error) {
            let param = {
                mobileNumber: "+" + SMSNumber,
                otp: "123456",
                shortMessage: notificationData.body,
            };
            try {
                let res = await sendSMS(param);
                if (res && res.isOTPSent == false) {
                    setAlerts({ type: "danger", message: res.success });
                } else {
                    await setAlerts({
                        type: "success",
                        message: notificationMessages.sendSMSSuccess,
                    });
                    ref.current.reset();
                }
            } catch (error) {
                console.error("Error sending OTP:", error);
                return false;
            }
        }
        setIsLoading(false);
    };

    // Function to reset the form
    const resetScheduledNotificationForm = () => {
        setNotificationData({});
        setNotificationValidate({});
        setCustomerEmail(null);
        setFile(null);
        setSelectedFile("");
        setToEmail("");
        setWhatsAppNumber("");
        setSMSNumber("");
    };

    const handleNotificationPlatformChange = (e) => {
        setNotificationPlatform(e.target.value);
        resetScheduledNotificationForm();
    };

    useEffect(() => {
        async function getCustomerList() {
            await getCustomerByCompanyId();
        }
        getCustomerList();
    }, []);

    return (
        <div className="content-dashboard-inventory">
            <div className="title-route-container menu-title-div">
                <h4 className="menu-title-h">Customer / Notification</h4>
            </div>

            <Card
                outline
                style={{
                    width: "100%",
                    height: "100%",
                    padding: "10px 10px 20px 10px",
                }}
            >
                <Container fluid>
                    {/* Notification type row */}
                    <Row className="mt-3">
                        <Col xs="12" md="2">
                            <p className="customer-notification-radio-header">
                                Notification Type
                            </p>
                        </Col>
                        <Col xs="12" md="3">
                            <div className="customer-notification-radio-group">
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        name="notificationType"
                                        id="notificationType"
                                        value={1}
                                        defaultChecked
                                        onChange={(e) => {
                                            setNotificationType(e.target.value);
                                        }}
                                    />
                                    <label htmlFor="notificationType">
                                        Broadcast
                                    </label>
                                </div>
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        name="notificationType"
                                        id="notificationType"
                                        value={2}
                                        onChange={(e) => {
                                            setNotificationType(e.target.value);
                                        }}
                                    />
                                    <label htmlFor="notificationType">
                                        Scheduled
                                    </label>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Platform selection row */}
                    <Row>
                        <Col xs="12" md="2">
                            <p className="customer-notification-radio-header">
                                Notification Platform
                            </p>
                        </Col>
                        <Col xs="12" md="3">
                            <div className="customer-notification-radio-group">
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        name="notificationPlatform"
                                        id="notificationPlatform"
                                        value={1}
                                        defaultChecked
                                        onChange={
                                            handleNotificationPlatformChange
                                        }
                                    />
                                    <label htmlFor="notificationPlatform">
                                        Email
                                    </label>
                                </div>
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        name="notificationPlatform"
                                        id="notificationPlatform"
                                        value={2}
                                        onChange={
                                            handleNotificationPlatformChange
                                        }
                                    />
                                    <label htmlFor="notificationPlatform">
                                        Whatsapp
                                    </label>
                                </div>
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        name="notificationPlatform"
                                        id="notificationPlatform"
                                        value={3}
                                        onChange={
                                            handleNotificationPlatformChange
                                        }
                                    />
                                    <label htmlFor="notificationPlatform">
                                        SMS
                                    </label>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Schedule notification details row */}
                    {notificationType == 2 && (
                        <Row>
                            <Col sm="12" md="3">
                                <FormGroup className="has-label customer-input-container">
                                    <label>Schedule Name *</label>
                                    <Input
                                        placeholder="Subject"
                                        type="text"
                                        valid={
                                            notificationValidate?.scheduleName ===
                                            "has-success"
                                        }
                                        invalid={
                                            notificationValidate?.scheduleName ===
                                            "has-danger"
                                        }
                                        value={
                                            notificationData?.scheduleName || ""
                                        }
                                        onChange={(e) => {
                                            let error = {
                                                ...notificationValidate,
                                            };
                                            if (
                                                !Common.verifyLength(
                                                    e.target.value,
                                                    1
                                                ) &&
                                                notificationType == 2
                                            ) {
                                                error.scheduleName =
                                                    "has-danger";
                                            } else {
                                                error.scheduleName =
                                                    "has-success";
                                            }
                                            setNotificationValidate(error);
                                            setNotificationData({
                                                ...notificationData,
                                                scheduleName: e.target.value,
                                            });
                                        }}
                                        maxLength={100}
                                    />
                                    <FormFeedback>
                                        Schedule name is required.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="3">
                                <FormGroup className="customer-input-container">
                                    <Label for="payment">Customer List *</Label>
                                    <Input
                                        name="customerList"
                                        placeholder="customer list"
                                        type="hidden"
                                        valid={
                                            notificationValidate?.customerEmail ===
                                            "has-success"
                                        }
                                        invalid={
                                            notificationValidate?.customerEmail ===
                                            "has-danger"
                                        }
                                    />
                                    <Select
                                        isMulti
                                        name="customerList"
                                        className="basic-multi-select customer-custom-select"
                                        classNamePrefix="select"
                                        value={customerEmail}
                                        options={getCustomerList()}
                                        placeholder="Select Customers"
                                        onChange={onCustomerChange}
                                    />
                                    <FormFeedback>
                                        Select atleast one customer from list.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    {/* Frequency and date range row */}
                    {notificationType == 2 && (
                        <Row>
                            <Col xs="12" md="3">
                                <FormGroup className="customer-input-container">
                                    <Label>From Date *</Label>
                                    <Input
                                        id="exampleDate"
                                        name="date"
                                        placeholder="from Date"
                                        type="date"
                                        valid={
                                            notificationValidate?.fromDate ===
                                            "has-success"
                                        }
                                        invalid={
                                            notificationValidate?.fromDate ===
                                            "has-danger"
                                        }
                                        value={notificationData?.fromDate || ""}
                                        onChange={(e) => {
                                            let error = {
                                                ...notificationValidate,
                                            };
                                            if (
                                                !Common.verifyLength(
                                                    e.target.value,
                                                    1
                                                )
                                            ) {
                                                error.fromDate = "has-danger";
                                            } else {
                                                error.fromDate = "has-success";
                                            }
                                            setNotificationValidate(error);
                                            setNotificationData({
                                                ...notificationData,
                                                fromDate: e.target.value,
                                            });
                                        }}
                                    />
                                    <FormFeedback>
                                        From date is required.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col xs="12" md="3">
                                <FormGroup className="customer-input-container">
                                    <Label>To Date *</Label>
                                    <Input
                                        id="exampleDate"
                                        name="date"
                                        placeholder="To Date"
                                        type="date"
                                        valid={
                                            notificationValidate?.toDate ===
                                            "has-success"
                                        }
                                        invalid={
                                            notificationValidate?.toDate ===
                                            "has-danger"
                                        }
                                        value={notificationData?.toDate || ""}
                                        onChange={(e) => {
                                            let error = {
                                                ...notificationValidate,
                                            };
                                            if (
                                                !Common.verifyLength(
                                                    e.target.value,
                                                    1
                                                )
                                            ) {
                                                error.toDate = "has-danger";
                                            } else {
                                                error.toDate = "has-success";
                                            }
                                            setNotificationValidate(error);
                                            setNotificationData({
                                                ...notificationData,
                                                toDate: e.target.value,
                                            });
                                        }}
                                    />
                                    <FormFeedback>
                                        To date is required.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col xs="12" md="3">
                                <FormGroup className="customer-input-container">
                                    <Label for="payment">Frequency *</Label>
                                    <Input
                                        name="payment"
                                        placeholder="Frequency"
                                        type="hidden"
                                        valid={
                                            notificationValidate?.frequency ===
                                            "has-success"
                                        }
                                        invalid={
                                            notificationValidate?.frequency ===
                                            "has-danger"
                                        }
                                    />
                                    <Select
                                        id="payment"
                                        name="payment"
                                        className="basic-multi-select customer-custom-select"
                                        classNamePrefix="select"
                                        options={getFrequencyList()}
                                        value={
                                            notificationData?.frequency
                                                ? getFrequencyList().find(
                                                      (option) =>
                                                          option.value ===
                                                          notificationData.frequency
                                                  )
                                                : null
                                        }
                                        onChange={(e) => {
                                            let error = {
                                                ...notificationValidate,
                                            };
                                            // Check if a valid option is selected
                                            if (!e || !e.value) {
                                                error.frequency = "has-danger"; // Set danger if no option is selected
                                            } else {
                                                error.frequency = "has-success"; // Set success if an option is selected
                                            }

                                            setNotificationValidate(error);
                                            setNotificationData({
                                                ...notificationData,
                                                frequency: e.value, // Store the value in state
                                            });
                                        }}
                                    />
                                    <FormFeedback>
                                        select any one frequency.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    {/* Broadcast Notification row */}
                    {notificationType == 1 && (
                        <Row>
                            {/* Email to field */}
                            {notificationPlatform == 1 && (
                                <Col xs="12" md="3">
                                    <FormGroup className="customer-input-container">
                                        <label>To *</label>
                                        <Input
                                            placeholder="Email To Address"
                                            type="email"
                                            value={toEmail}
                                            onChange={(e) => {
                                                let error = {
                                                    ...notificationValidate,
                                                };
                                                if (
                                                    !Common.verifyEmail(
                                                        e.target.value
                                                    ) ||
                                                    !Common.verifyLength(
                                                        e.target.value,
                                                        1
                                                    )
                                                ) {
                                                    error.emailTo =
                                                        "has-danger";
                                                } else {
                                                    error.emailTo =
                                                        "has-success";
                                                }
                                                setNotificationValidate(error);
                                                setToEmail(e.target.value);
                                            }}
                                            valid={
                                                notificationValidate?.emailTo ===
                                                "has-success"
                                            }
                                            invalid={
                                                notificationValidate?.emailTo ===
                                                "has-danger"
                                            }
                                            maxLength={100}
                                        />
                                        <FormFeedback>
                                            Please enter valid email.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            )}

                            {/* WhatsApp number field */}
                            {notificationPlatform == 2 && (
                                <Col xs="12" md="3">
                                    <FormGroup className="has-label customer-input-container">
                                        <label>To Number *</label>
                                        <Input
                                            name="watsAppNumber"
                                            placeholder="Registered WhatsApp Number"
                                            type="hidden"
                                            valid={
                                                notificationValidate?.whatsAppNumber ===
                                                "has-success"
                                            }
                                            invalid={
                                                notificationValidate?.whatsAppNumber ===
                                                "has-danger"
                                            }
                                        />
                                        <PhoneInput
                                            inputProps={{
                                                autoFocus: true,
                                            }}
                                            containerClass="customer-phone-input-container"
                                            buttonClass="customer-flag-search-btn"
                                            dropdownClass="customer-flag-dropdown"
                                            country={"in"}
                                            placeholder="WhatsApp Number"
                                            onChange={(value) => {
                                                const regex = /[^0-9]/g;

                                                let error = {
                                                    ...notificationValidate,
                                                };
                                                if (
                                                    !Common.verifyLength(
                                                        value,
                                                        12
                                                    ) ||
                                                    regex.test(value)
                                                ) {
                                                    error.whatsAppNumber =
                                                        "has-danger";
                                                } else {
                                                    error.whatsAppNumber =
                                                        "has-success";
                                                }
                                                setNotificationValidate(error);

                                                setWhatsAppNumber(value);
                                            }}
                                            enableLongNumbers={true} // Allows longer phone numbers
                                        />
                                        <FormFeedback>
                                            Invalid WhatsApp Number.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            )}

                            {/* SMS number field */}
                            {notificationPlatform == 3 && (
                                <Col xs="12" md="3">
                                    <FormGroup className="has-label customer-input-container">
                                        <label>Number *</label>
                                        <Input
                                            name="registerMobileNumber"
                                            placeholder="Registered Mobile Number"
                                            type="hidden"
                                            invalid={
                                                notificationValidate?.SMSNumber ===
                                                "has-danger"
                                            }
                                            valid={
                                                notificationValidate?.SMSNumber ===
                                                "has-success"
                                            }
                                        />
                                        <PhoneInput
                                            inputProps={{
                                                autoFocus: true,
                                            }}
                                            containerClass="customer-phone-input-container"
                                            buttonClass="customer-flag-search-btn"
                                            dropdownClass="customer-flag-dropdown"
                                            country={"in"}
                                            placeholder="Number"
                                            onChange={(value) => {
                                                const regex = /[^0-9]/g;
                                                let error = {
                                                    ...notificationValidate,
                                                };
                                                if (
                                                    !Common.verifyLength(
                                                        value,
                                                        12
                                                    ) ||
                                                    regex.test(value)
                                                ) {
                                                    error.SMSNumber =
                                                        "has-danger";
                                                } else {
                                                    error.SMSNumber =
                                                        "has-success";
                                                }
                                                setNotificationValidate(error);
                                                setSMSNumber(value);
                                            }}
                                            enableLongNumbers={true} // Allows longer phone numbers
                                        />
                                        <FormFeedback>
                                            Invalid Phone Number.
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                    )}

                    {/* subject and attachment row */}
                    <Row className="mt-3">
                        <Col sm="12" md="9">
                            <FormGroup className="has-label custom-input-container">
                                <label>Subject *</label>
                                <Input
                                    placeholder="Subject"
                                    type="text"
                                    valid={
                                        notificationValidate?.subject ===
                                        "has-success"
                                    }
                                    invalid={
                                        notificationValidate?.subject ===
                                        "has-danger"
                                    }
                                    value={notificationData?.subject || ""}
                                    onChange={(e) => {
                                        let error = {
                                            ...notificationValidate,
                                        };
                                        if (
                                            !Common.verifyLength(
                                                e.target.value,
                                                1
                                            )
                                        ) {
                                            error.subject = "has-danger";
                                        } else {
                                            error.subject = "has-success";
                                        }
                                        setNotificationValidate(error);
                                        setNotificationData({
                                            ...notificationData,
                                            subject: e.target.value,
                                        });
                                    }}
                                    maxLength={100}
                                />
                                <FormFeedback>
                                    Subject is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        {notificationPlatform != 3 && (
                            <Col sm="12" md="3">
                                <FormGroup className="custom-file-upload-btn-container m-0">
                                    <span>
                                        File Name:&nbsp;
                                        {notificationData?.attachedFileName
                                            ? notificationData?.attachedFileName
                                            : file?.name}
                                    </span>
                                </FormGroup>
                                <FormGroup className="custom-file-upload-btn-container">
                                    <label htmlFor="notificationAttachment">
                                        <FaPaperclip size={20} /> Add Attachment
                                    </label>
                                    <input
                                        accept="image/x-png,image/svg,image/jpeg,image/jpg"
                                        onChange={(e) => uploadProduct(e)}
                                        type="file"
                                        name="file"
                                        id="notificationAttachment"
                                        hidden
                                    />
                                </FormGroup>
                            </Col>
                        )}
                    </Row>

                    {/* body and attachment preview row */}
                    <Row>
                        <Col sm="12" md="9">
                            <FormGroup className="has-label customer-input-container">
                                <label>Body *</label>
                                <Input
                                    placeholder="Body"
                                    className="textarea"
                                    type="textarea"
                                    rows="5"
                                    valid={
                                        notificationValidate?.body ===
                                        "has-success"
                                    }
                                    invalid={
                                        notificationValidate?.body ===
                                        "has-danger"
                                    }
                                    value={
                                        notificationData?.body
                                            ? notificationData?.body
                                            : ""
                                    }
                                    onChange={(e) => {
                                        let error = {
                                            ...notificationValidate,
                                        };
                                        if (
                                            !Common.verifyLength(
                                                e.target.value,
                                                1
                                            )
                                        ) {
                                            error.body = "has-danger";
                                        } else {
                                            error.body = "has-success";
                                        }
                                        setNotificationValidate(error);
                                        setNotificationData({
                                            ...notificationData,
                                            body: e.target.value,
                                        });
                                    }}
                                    maxLength={200}
                                />
                                <FormFeedback>Body is required.</FormFeedback>
                            </FormGroup>
                        </Col>
                        {notificationPlatform != 3 && (
                            <Col
                                sm="12"
                                md="3"
                                className="d-flex align-items-center"
                            >
                                <div className="inventory-image-preview justify-content-start">
                                    <div className="inventory-img-container">
                                        {file ? (
                                            <>
                                                <img
                                                    className="displayImg"
                                                    src={URL.createObjectURL(
                                                        file
                                                    )}
                                                    alt="no pic"
                                                />
                                                <IoMdCloseCircle
                                                    style={{
                                                        top: "-10px",
                                                        right: "-10px",
                                                    }}
                                                    size={20}
                                                    className="remove-inventory-img-btn"
                                                    onClick={removeImage}
                                                />
                                            </>
                                        ) : 
                                          notificationData?.attachedFileName ? (
                                            <img
                                                className="displayImg"
                                                src={`${ERP_CDN_IMAGE_URL}/${notificationData?.attachedFileName}`}
                                                alt={notificationData?.attachedFileName}
                                            />
                                        ) : (
                                            <img
                                                className="displayImg"
                                                src={`${ERP_CDN_IMAGE_URL}/placeholder.jpeg`}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if logo also fails
                                                    e.target.src = Placeholder;
                                                }}
                                                alt="nopic"
                                            />
                                        )}
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>

                    {/* Submit button row */}
                    <Row className="mt-3">
                        <Col sm="12" md="3">
                            <CustomButton
                                label="Save Details"
                                onClick={handleSaveNotification}
                                disabled={isLoading}
                                icon={
                                    isLoading ? (
                                        <FaSpinner className="fa-spin" />
                                    ) : null
                                }
                            />
                        </Col>
                    </Row>

                    {notificationType == 2 &&
                        (isLoading ? (
                            <Loader />
                        ) : (
                            <NotificationScheduleList
                                notificationTypeId={notificationPlatform}
                                notificationUpdateClick={(
                                    notificationSettingID
                                ) =>
                                    notificationUpdateClick(
                                        notificationSettingID
                                    )
                                }
                            />
                        ))}
                </Container>
            </Card>
        </div>
    );
};

export default NotificationSchedule;
