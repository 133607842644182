import { forwardRef, useImperativeHandle, useRef, useState } from "react";

// reactstrap components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadMore from "components/Button/LoadMore";
import ChildLoader from "components/Loader/ChildLoader";
import { useLoginStore } from "hooks/Login";
import { useTemplateStore } from "hooks/Template";
import { useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import {
  Card,
  Col,
  Input
} from "reactstrap";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import '../../assets/styles/bills/TamplateList.css';
import "../../assets/styles/CreateBill.css";
import "../../assets/styles/Invoice.css";

const pagesize = new CompanyProfileRepository().getPageSize();

const TemplateList = forwardRef(( {setLoading}, ref ) => {
  const [
    { },
    { getTemplateList, getTemplateDetailsById },
    { updateTemplateActiveStatus },
  ] = useTemplateStore();
  const [templateData, setTemplateData] = useState({});
  const dt = useRef(null);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [{ }, { }, { setAlerts }] = useLoginStore();

  const handleLoadMore = () => {
    setLoadingMore(true);
    setPage((prev) => prev + 1);
    const newValues = templateData.slice(
      page * pagesize,
      page * pagesize + pagesize
    );

    setTableData((prev) => [...prev, ...newValues]);
    setLoadingMore(false);
  };

  async function getTemplate() {
    setPage(1);
    setLoadingMore(true);
    let templateList = await getTemplateList();
    // console.log(templateList);
    setTemplateData(templateList);
    setTableData(templateList.slice(0, pagesize));
    setLoadingMore(false);
  }

  useEffect(() => {
    getTemplate();
  }, []);

  useEffect(() => {
    async function updateTemplate() {
      setLoadingMore(true);
      let templateList = await getTemplateList();
      setTemplateData(templateList);
      setTableData(templateList.slice(0, page * pagesize));
      setLoadingMore(false);
    }

    updateTemplate();
  }, [loadingStatus]);

  const getTemplateDataById = async (rowData) => {
    setLoading(true)
    await getTemplateDetailsById({ templateID: rowData });
    setLoading(false)
  };

  const changeStatus = async (params) => {
    setLoadingStatus(true);
    try {
      const res = await updateTemplateActiveStatus(params);
      await setAlerts({
        type: "success",
        message: "Template status changed successfully.",
      });
      setLoadingStatus(false);
    } catch (error) {
      await setAlerts({
        type: "danger",
        message: "Template status did not change.",
      });
      setLoadingStatus(false);
    }
  };

  const ActionTemplate = ({ rowData }) => {
    return (
      <div class="d-flex justify-content-around w-100">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => getTemplateDataById(rowData?.templateID)}
        // Pass the billId when the edit button is clicked
        >
          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="lg" />
        </button>
        <button
          type="button"
          class="btn btn-link"
        // onClick={() => onDelete(rowData)}
        >
          <FontAwesomeIcon icon="fa-solid fa-trash-can" size="lg" />
        </button>
      </div>
    );
  };

  // To get the latest template list on save or update of template
  useImperativeHandle(ref, () => {
    return {
      getTemplate,
    }
  });

  return (
    <Card className="templates-table-card">
      <div className="title-row">
        <h4>Template List</h4>
      </div>

      {loadingStatus ? (
        <>
          <ChildLoader />
        </>
      ) : (
        <>
          <div style={{ overflowX: "auto" }}>
            <table className="templates-table">
              <thead>
                <tr>
                  <th className="template-id-col">Template Id</th>
                  <th className="template-name-col">Template Name</th>
                  <th className="template-type-col">Template Type</th>
                  <th className="template-edit-col">Edit</th>
                  <th className="template-action-col">Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 &&
                  tableData.map((item, index) => (
                    <tr key={index}>
                      <td className="template-id-col cutomize-form-control">
                        {item.templateID}
                      </td>
                      <td className="template-name-col cutomize-form-control">
                        {item.templateName}
                      </td>
                      <td className="template-type-col cutomize-form-control">
                        {item.templateType}
                      </td>
                      <td className="template-edit-col cutomize-form-control">
                        <button
                          type="button"
                          className="template-edit-btn"
                          onClick={() => getTemplateDataById(item.templateID)}
                        >
                          <FaEdit size={20} />
                        </button>
                      </td>
                      <td className="template-action-col cutomize-form-control">
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          value={item.isActive}
                          className={`template-status-dropdown ${item.isActive ? 'template-active' : 'template-inactive'}`}
                          onChange={(e) => changeStatus({
                            isActive: e.target.value,
                            templateID: item.templateID,
                          })
                          }
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </Input>
                        {/* {item.isActive === true ? (
                          <>
                            <img
                              src={shutdown}
                              alt="shutdown"
                              className="icon-template-page delete-icon"
                              onClick={() =>
                                changeStatus({
                                  isActive: false,
                                  templateID: item.templateID,
                                })
                              }
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={start}
                              alt="start"
                              className="icon-template-page delete-icon"
                              onClick={() =>
                                changeStatus({
                                  isActive: true,
                                  templateID: item.templateID,
                                })
                              }
                            />
                          </>
                        )} */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* {tableData && !isEmpty(tableData) && (
          <DataTable
            value={tableData}
            tableStyle={{ minWidth: "25rem" }}
            pt={{
              root: { className: "table" },
            }}
          >
            <Column field="templateID" header="Template Id"></Column>
            <Column field="templateName" header="Template Name"></Column>
            <Column field="templateType" header="Template Type"></Column>
            <Column
              className="text-center"
              header="Action"
              body={(rowData) => <ActionTemplate rowData={rowData} />}
            ></Column>
          </DataTable>
        )} */}
      <Col className="text-center">
        {loadingMore ? (
          <ChildLoader />
        ) : (
          <LoadMore
            totalData={tableData.length}
            handleLoadMore={() => handleLoadMore()}
          />
        )}
      </Col>
    </Card>
  );
});

export default TemplateList;
