import { useCreateBillStore } from "hooks/CreateBill";
import { AutoComplete } from "primereact/autocomplete";
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Input,
  Row,
  Tooltip,
  Collapse,
  Button,
  CardBody,
  Card,
  Container,
  Modal,
  ModalBody,
} from "reactstrap";
import AddCustomerModal from "../AddCustomerModal";
import EditCustomerModal from "../EditCustomerModal";
import CustomerBillHistoryModal from "../CustomerBillHistoryModal";
import TemplateButton from "../TemplateButton";
import SuggestedProductsButton from "../SuggestedProductsButton";
import billHistory from "assets/img/icons/createBill/bill_history.png";
import draft from "assets/img/icons/createBill/draft.png";
import CustomButton from "components/Button/CustomButton";
import { MdOutlineEditNote } from "react-icons/md";
import { HiOutlineTemplate } from "react-icons/hi";
import { FaUserPlus } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useAzureStore } from "hooks/Azure";
import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";
import { RiFileEditFill } from "react-icons/ri";
import { GrView } from "react-icons/gr";
import { RiUserAddFill } from "react-icons/ri";
import { FaHistory } from "react-icons/fa";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import BillHistoryModal from "views/BillHistory/BillHistoryModal";
import PatientBillForm from "views/CreateBill/PatientBillForm";
import { AiOutlineFileSearch } from "react-icons/ai";

import { IoClose } from "react-icons/io5";
import { useLoginStore } from "hooks/Login";

const pageSize = new CompanyProfileRepository().getPageSize();

const CustomerDetails = ({
  customerData,
  setCustomerData,
  selectedCustomer,
  setSelectedCustomer,
  handleTemplateSelect,
  suggestedProductList,
  handleSuggestedProductSelect,
  selectedCustomerID,
  setSelectedCustomerID,
  recentBillId,
  setSelectedRecentProduct,
  fetchBillDataById,
}) => {
  const [{}, { getCustomerDetail, Get_Bills_By_CustomerID }, {}] =
    useCreateBillStore();
  const [billFormModal, setBillFormModal] = useState(false);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [recentPopoverOpen, setRecentPopoverOpen] = useState(false);
  const [suggestedPopoverOpen, setSuggestedPopoverOpen] = useState(false);
  const [customerBillHistoryData, setCustomerBillHistoryData] = useState([]);
  const [backdrop, setBackdrop] = useState(true);
  const [keyboard, setKeyboard] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [noResultFound, setNoResultFound] = useState(false);
  var localRecentBillid = localStorage.getItem("recentBillId");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const popupRef = useRef();
  const [{}, {}, { createCustomer }] = useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  // Initialize state from localStorage
  const [isOpenViewBill, setIsOpenViewBill] = useState(() => {
    const storedValue = localStorage.getItem("isOpenViewBill");
    return storedValue !== null
      ? JSON.parse(storedValue)
      : recentBillId && recentBillId !== 0;
  });

  // Function to toggle view bill and store state in localStorage
  const toggleViewBill = () => {
    setIsOpenViewBill((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isOpenViewBill", JSON.stringify(newState));
      return newState;
    });
  };

  useEffect(() => {
    // Store recentBillId in localStorage whenever it changes
    if (recentBillId && recentBillId !== 0) {
      localStorage.setItem("recentBillId", recentBillId);
    }
  }, [recentBillId]);

  const toggleRecentPopover = () => {
    setRecentPopoverOpen(!recentPopoverOpen);
  };

  const toggleSuggestedPopover = () => {
    setSuggestedPopoverOpen(!suggestedPopoverOpen);
  };
  const [{}, { fetchImagesFromAzure }, {}] = useAzureStore();
  const recentProductRef = useRef(null);
  const suggestedProductRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (recentBillId && recentBillId !== 0) {
      setIsOpenViewBill(true);
    }
  }, [recentBillId]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        recentProductRef.current &&
        !recentProductRef.current.contains(event.target)
      ) {
        setRecentPopoverOpen(false);
      }
      if (
        suggestedProductRef.current &&
        !suggestedProductRef.current.contains(event.target)
      ) {
        setSuggestedPopoverOpen(false);
      }
    }
    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLoadMore = () => {
    const data = customerBillHistoryData.slice(
      pageNumber * pageSize,
      pageNumber * pageSize + pageSize
    );
    setTableData((prev) => [...prev, ...data]);
    setPageNumber((prev) => prev + 1);
  };

  const autoCompleteRef = useRef(null);

  const retriveCustomers = async (queryText) => {
    try {
      let customerList = await getCustomerDetail(queryText);

      const modifyCustomerNames = () => {
        customerList.forEach((customer) => {
          // Modify customer name only if phoneNumber is not null, undefined, or an empty string
          if (customer.phoneNumber) {
            customer.customerName = `${customer.customerName} (${customer.phoneNumber})`;
          }
        });
      };

      modifyCustomerNames();

      setFilteredCustomer(customerList); // Assume this is setting state, but unrelated to return value
      if (customerList.length === 0) {
        setNoResultFound(true);
      }

      return customerList; // Make sure to return the list here
    } catch (error) {
      console.error("Error retrieving customers:", error);
      throw error; // Ensure the error propagates properly
    }
  };

  const filterCustomer = (event) => {
    retriveCustomers(event.query);
  };

  useEffect(() => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }
  }, []);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipDraft, setTooltipDraft] = useState(false);

  const [tooltipOpenSuggestedProduct, setTooltipOpenSuggestedProduct] =
    useState(false);
  const [tooltipBillHistory, setTooltipBillHistory] = useState(false);
  const [tooltipRecentProducts, setTooltipRecentProducts] = useState(false);
  const [tooltipRecentBill, setTooltipRecentBill] = useState(false);
  const [tooltipModifyRecentBill, settooltipModifyRecentBill] = useState(false);

  const toggletooltipRecentProducts = () =>
    setTooltipRecentProducts(!tooltipRecentProducts);
  const toggletooltipRecentBill = () =>
    setTooltipRecentBill(!tooltipRecentBill);
  const toggletooltipModifyRecentBill = () =>
    settooltipModifyRecentBill(!tooltipModifyRecentBill);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleTooltipSuggested = () =>
    setTooltipOpenSuggestedProduct(!tooltipOpenSuggestedProduct);
  const toggleTooltipBillHistory = () =>
    setTooltipBillHistory(!tooltipBillHistory);
  const toggleTooltipDraft = () => setTooltipDraft(!tooltipDraft);

  const fetchPDF = async (name, type) => {
    let pdfURL = await fetchImagesFromAzure(name, "bill");
    if (type.toLowerCase() === "preview") {
      const response = await fetch(pdfURL);
      const pdfData = await response.blob();
      const file = new Blob([pdfData], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } else {
      window.open(pdfURL, "_blank");
    }
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
  };

  const fetchCustomerBillHistory = async (customerID) => {
    if (customerID) {
      setPageNumber(1);
      const customerBillHistory = await Get_Bills_By_CustomerID(customerID);

      setCustomerBillHistoryData(customerBillHistory);
      const data = customerBillHistory.slice(0, pageSize);
      setTableData(data);
      setBillFormModal(true);
      // toggle();
    }
  };

  const togglePopup = () => {
    // retrieveTemplatesList()
    setPopupVisible(!isPopupVisible);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false); // Close the popup if clicked outside
      }
    };

    // Add event listener when the popup is visible
    if (isPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when component unmounts or popup is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupVisible, setPopupVisible]);

  const handleQuickAddCustomer = async (e) => {
    let params = {
      customerName: selectedCustomer,
      activeStatus: 1,
      emailId: "",
      phoneNumber: "",
      description: "",
      pincode: "",
      address1: "",
      address2: "",
      street_Area: "",
      city: "",
      state: "",
      country: "",
    };

    try {
      const response = await createCustomer(params);
      if (response?.data == false) {
        await setAlerts({
          type: "danger",
          message: response?.message,
        });
      }

      if (response?.data == true) {
        await setAlerts({
          type: "success",
          message: "Customer successfully created",
        });

        // Now call the new function after successful customer creation
        const customerData = await handleRetrieveAndModifyCustomers(
          selectedCustomer
        );
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      await setAlerts({
        type: "danger",
        message: "Error creating customer",
      });
    }
  };

  const handleRetrieveAndModifyCustomers = async (queryText) => {
    try {
      let customerList = await getCustomerDetail(queryText);

      const modifyCustomerNames = () => {
        customerList.forEach((customer) => {
          // Modify customer name only if phoneNumber is not null, undefined, or an empty string
          if (customer.phoneNumber) {
            customer.customerName = `${customer.customerName} (${customer.phoneNumber})`;
          }
        });
      };

      modifyCustomerNames();

      setFilteredCustomer(customerList); // Set the filtered customer state
      if (customerList.length === 0) {
        setNoResultFound(true);
      }
      console.error("modifiedd customers:", customerList);
      setSelectedCustomer(customerList?.customerName);
      if (customerList?.customerID) {
        const customerID = customerList?.customerID;
        setSelectedCustomerID(customerID);
      } else {
        setSelectedCustomerID(null);
        setCustomerData({
          customerName: "",
          address1: "",
          address2: "",
          address: "",
          phoneNumber: "",
          emailId: "",
        });
      }
      const newAddress = `${customerList?.address1 ?? ""}, ${
        customerList?.address2 ?? ""
      }\n${customerList?.city ?? ""}, ${customerList?.state ?? ""}`;
      customerData.customerName = customerList?.customerName ?? "";
      customerData.address = newAddress;
      customerData.phoneNumber = customerList?.phoneNumber ?? "";
      customerData.emailId = customerList?.emailId ?? "";
      // return customerList; // Return the modified customer list
    } catch (error) {
      console.error("Error retrieving customers:", error);
      throw error; // Ensure the error propagates properly
    }
  };

  return (
    <div className="pb-3" style={{ borderBottom: "1px solid #E0E0E0" }}>
      <Row className="align-items-center d-flex" style={{ rowGap: "7px" }}>
        <Col xs="12" sm="12" md="6" lg="3">
          <AutoComplete
            ref={autoCompleteRef}
            value={selectedCustomer}
            suggestions={filteredCustomer}
            completeMethod={filterCustomer}
            field="customerName"
            placeholder="Customer Name"
            showEmptyMessage={true}
            emptyMessage={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>No results found</span>
                <CustomButton
                  label="Add customer"
                  id="recentProduct"
                  customStyle="custom-primary-button"
                  onClick={() => handleQuickAddCustomer()}
                />
                {/* <AddCustomerModal
                                    customerName={
                                        noResultFound ? selectedCustomer : ""
                                    }
                                    setNoResultFound={() =>
                                        setNoResultFound(false)
                                    }
                                    label="Add Customer"
                                /> */}
              </div>
            }
            minLength={1}
            onChange={(e) => {
              setSelectedCustomer(e.value);
              if (e.value?.customerID) {
                const customerID = e.value?.customerID;
                setSelectedCustomerID(customerID);
              } else {
                setSelectedCustomerID(null);
                setCustomerData({
                  customerName: "",
                  address1: "",
                  address2: "",
                  address: "",
                  phoneNumber: "",
                  emailId: "",
                });
              }
              const newAddress = `${e.value?.address1 ?? ""}, ${
                e.value?.address2 ?? ""
              }\n${e.value?.city ?? ""}, ${e.value?.state ?? ""}`;
              customerData.customerName = e.value?.customerName ?? "";
              customerData.address = newAddress;
              customerData.phoneNumber = e.value?.phoneNumber ?? "";
              customerData.emailId = e.value?.emailId ?? "";
            }}
            pt={{
              panel: {
                className: "autocomplete-Zindex",
              },
              input: {
                root: {
                  className: "custom-width-input form-control",
                },
              },
            }}
            className="custom-autocomplete"
            // style={{ width: "105%" }}
            delay={300}
          />
        </Col>

        <Col xs="12" sm="12" md="3" lg="2">
          <Input
            type="text"
            style={{
              width: "107%", // Utilize full width of the column
            }}
            className="custom-input-control"
            placeholder="Mobile Number"
            delay={300}
            value={customerData?.phoneNumber}
            readOnly
          />
        </Col>

        <Col
          xs="12"
          sm="12"
          md="3"
          lg="2" // Changed from lg="2" to lg="3" to utilize more space
          style={{
            display: "flex",
          }}
        >
          <Input
            style={{
              width: "100%", // Utilize full width of the column
            }}
            type="text"
            className="custom-input-control"
            placeholder="Email"
            delay={300}
            value={customerData?.emailId}
            readOnly
          />
        </Col>

        <Col xs="12" sm="8" md="4" lg="3">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
            className="filter-container-recent"
            ref={popupRef}
          >
            <button
              disabled={!selectedCustomerID}
              className="add-customer-btn"
              id="recentBill"
              type="button"
              onClick={() => fetchCustomerBillHistory(selectedCustomerID)}
              style={{
                backgroundColor: selectedCustomerID ? "#434CE6" : "#e0e0e0",
                color: selectedCustomerID ? "#fff" : "#808080",
                cursor: selectedCustomerID ? "pointer" : "not-allowed",
                border: selectedCustomerID
                  ? "1px solid #434CE6"
                  : "1px solid #e0e0e0",
                padding: "0px",
              }}
            >
              <AiOutlineFileSearch size={18} />
            </button>
            <Tooltip
              autohide={true}
              flip={true}
              placement="top"
              isOpen={tooltipBillHistory}
              target="recentBill"
              toggle={toggleTooltipBillHistory}
              style={{
                backgroundColor: "#434CE6",
                color: "#ffffff",
              }}
            >
              Bill History
            </Tooltip>

            <AddCustomerModal
              customerName={noResultFound ? selectedCustomer : ""}
              setNoResultFound={() => setNoResultFound(false)}
            />
            <EditCustomerModal />
            <button
              style={{
                textDecoration: "none",
              }}
              id="recentProducts"
              className="add-customer-btn"
              type="button"
              onClick={() => {
                // togglePopup();
                setPopupVisible(true);
              }}
            >
              <MdOutlineProductionQuantityLimits size={16} />
            </button>
            {isPopupVisible && (
              <>
                <div
                  style={{
                    left: "-40px",
                  }}
                  className="filter-popup-recent"
                >
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      width: "100%",
                    }}
                    className="scrollbar-custom"
                  >
                    <div className="template-list-container">
                      {suggestedProductList &&
                      suggestedProductList.length > 0 ? (
                        suggestedProductList.map((item, index) => (
                          <div
                            key={index}
                            className="list-item-template-style"
                            onClick={() => {
                              setSelectedRecentProduct({
                                productId: item.productId,
                                productName: item.productName,
                                applicableDiscountAmount:
                                  item.applicableDiscountAmount,
                                available_qty: item.available_qty,
                                brand: item.brand,
                                categoryId: item.categoryId,
                                categoryName: item.categoryName,
                                cgst: item.cgst,
                                companyId: item.companyId,
                                discountAmount: item.discountAmount,
                                discountFixed: item.discountFixed,
                                discountPercentage: item.discountPercentage,
                                expiryDate: item.expiryDate,
                                gstRate: item.gstRate,
                                igstRate: item.igstRate,
                                isTax1Percentage: item.isTax1Percentage,
                                isTax2Percentage: item.isTax2Percentage,
                                isazureSearchData: item.isazureSearchData,
                                lead_time: item.lead_time,
                                maximumRetailPrice: item.maximumRetailPrice,
                                maximum_stock: item.maximum_stock,
                                measure: item.measure,
                                minimum_stock: item.minimum_stock,
                                mrpDiscountAmount: item.mrpDiscountAmount,
                                netPrice: item.netPrice,
                                netProductCost: item.netProductCost,
                                productCode: item.productCode,
                                productDescription: item.productDescription,
                                productImageName: item.productImageName,
                                productItemcode: item.productItemcode,
                                productShortcode: item.productShortcode,
                                productType: item.productType,
                                product_discount: item.product_discount,
                                reorder_point: item.reorder_point,
                                sgst: item.sgst,
                                sizeDescription: item.sizeDescription,
                                sku: item.sku,
                                subCategoryId: item.subCategoryId,
                                subCategoryName: item.subCategoryName,
                                tax1: item.tax1,
                                tax1Description: item.tax1Description,
                                tax2: item.tax2,
                                tax2Description: item.tax2Description,
                                taxAmount: item.taxAmount,
                                un_used_quantity: item.un_used_quantity,
                                unit: item.unit,
                                unitItems: item.unitItems,
                                unitPrice: item.unitPrice,
                                upc_code: item.upc_code,
                              });
                              // handleTemplateSelect(item.productId);
                              // closePopup();
                              setPopupVisible(false);
                            }}
                          >
                            {item.productName}
                          </div>
                        ))
                      ) : (
                        <div className="text-center">No Products Found</div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <Tooltip
              autohide={true}
              flip={true}
              placement="top"
              isOpen={tooltipRecentProducts}
              target="recentProducts"
              toggle={toggletooltipRecentProducts}
              style={{
                backgroundColor: "#434CE6",
                color: "#ffffff",
              }}
            >
              Recent Products
            </Tooltip>
            <a
              href={
                localRecentBillid ? `/admin/editBill/${localRecentBillid}` : "#"
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
              }}
            >
              <button
                id="modfifyRecentBill"
                className="add-customer-btn"
                type="button"
                onClick={handleButtonClick}
                disabled={!localRecentBillid} // Disable if localRecentBillid is not available
                style={{
                  backgroundColor: localRecentBillid ? "#434CE6" : "#e0e0e0",
                  color: localRecentBillid ? "#fff" : "#808080",
                  cursor: localRecentBillid ? "pointer" : "not-allowed",
                  border: localRecentBillid
                    ? "1px solid #434CE6"
                    : "1px solid #e0e0e0",
                  padding: "0px",
                }}
              >
                <RiFileEditFill size={16} />
              </button>
            </a>
            <Tooltip
              autohide={true}
              flip={true}
              placement="top"
              isOpen={tooltipModifyRecentBill}
              target="modfifyRecentBill"
              toggle={toggletooltipModifyRecentBill}
              style={{
                backgroundColor: "#434CE6",
                color: "#ffffff",
              }}
            >
              Modify Recent Bill
            </Tooltip>
            <button
              id="viewRecentBill"
              style={{
                backgroundColor: localRecentBillid ? "#434CE6" : "#e0e0e0",
                color: localRecentBillid ? "#fff" : "#808080",
                cursor: localRecentBillid ? "pointer" : "not-allowed",
                border: localRecentBillid
                  ? "1px solid #434CE6"
                  : "1px solid #e0e0e0",
                padding: "0px",
              }}
              onClick={(e) => {
                if (localRecentBillid) {
                  // Only fetch PDF if localRecentBillid is available
                  fetchPDF(localRecentBillid, "preview");
                  handleButtonClick(e);
                }
              }}
              className="add-customer-btn"
              type="button"
            >
              <GrView size={16} />
            </button>
            <Tooltip
              autohide={true}
              flip={true}
              placement="top"
              isOpen={tooltipRecentBill}
              target="viewRecentBill"
              toggle={toggletooltipRecentBill}
              style={{
                backgroundColor: "#434CE6",
                color: "#ffffff",
              }}
            >
              View Recent Bill
            </Tooltip>
          </div>
        </Col>

        <Col
          xs="12"
          sm="4"
          md="3"
          lg="2"
          className="d-flex justify-content-end align-items-center"
          style={{ gap: "10px" }}
        >
          <TemplateButton
            toggleRecentPopover={toggleRecentPopover}
            handleTemplateSelect={handleTemplateSelect}
          />
        </Col>
      </Row>
      <CustomerBillHistoryModal
        modal={billFormModal}
        toggle={() => setBillFormModal(false)}
        backdrop={true}
        keyboard={true}
        customerBillHistoryData={customerBillHistoryData}
        customerName={selectedCustomer?.customerName}
        handleLoadMore={handleLoadMore}
        tableData={tableData}
        fetchBillDataById={fetchBillDataById}
      />
    </div>
  );
};

export default CustomerDetails;
