import React, { useState } from "react";
import dashboardImg from "assets/img/landing-page/hero-dashboard.webp";
import { IoClose } from "react-icons/io5";
import PageLoader from "components/Loader/PageLoader";
import PhoneInput from "react-phone-input-2";
import CustomButton from "components/Button/CustomButton";
import { useNavigate } from "react-router-dom";
import { useNotificationStore } from "hooks/Notification";
import { Form, FormGroup, Modal, ModalBody, Input, Label } from "reactstrap";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const Hero = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [consent, setConsent] = useState(false);
    const [emailValidation, setEmailValidation] = useState("");
    const [mobileValidation, setMobileValidation] = useState(""); // State to track mobile validation
    const [consentValidation, setConsentValidation] = useState("");
    const [{}, { SendSignupEmail }, {}] = useNotificationStore();
    const mobileInputValidClass = "form-control";
    const navigate = useNavigate();

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);

        // Reset fields when closing the modal
        if (!isModalOpen) {
            resetForm();
        }
    };

    const resetForm = () => {
        setEmail("");
        setMobile("");
        setEmailValidation("");
        setMobileValidation("");
        setConsentValidation("");
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        // Validate email when changed
        if (Common.verifyLength(value, 5) && Common.verifyEmail(value)) {
            setEmailValidation("has-success"); // Email is valid
        } else {
            setEmailValidation("has-danger"); // Email is invalid
        }
    };

    const handleMobileChange = (e) => {
        setMobile("+" + e);
        // Simple validation for mobile number (you can modify it based on your requirements)
        if (e.length >= 10) {
            setMobileValidation("has-success");
        } else {
            setMobileValidation("has-danger");
        }
    };

    const handleLogin = async (e) => {
        setLoader(true);
        e.preventDefault(); // Prevent default form submission

        let isValid = true;

        // Validate email field
        if (!Common.verifyLength(email, 5) || !Common.verifyEmail(email)) {
            setEmailValidation("has-danger");
            isValid = false;
            setLoader(false);
        } else {
            setEmailValidation("has-success");
        }

        // Validate mobile field
        if (mobile === "" || mobile.length == 0) {
            setMobileValidation("has-danger");
            isValid = false;
            setLoader(false);
        } else {
            setMobileValidation("has-success");
        }

        if (consent === false) {
            setConsentValidation("has-danger");
            isValid = false;
            setLoader(false);
        } else {
            setConsentValidation("has-success");
        }

        // Proceed only if both fields are valid
        if (isValid) {
            let adminEmail = "admin@apsthreeai.ai";
            let emailFormData = new FormData();
            emailFormData.append("EmailToAddress", adminEmail);
            emailFormData.append("EmailSubject", `Demo Login`);
            emailFormData.append(
                "EmailBody",
                `Email: ${email}\nMobile: ${mobile}`
            );

            try {
                let res = await SendSignupEmail(emailFormData);
                if (res) {
                    setLoader(false);
                    toggleModal();
                    navigate(AUTH_URL_DEMO_LOGIN); // Navigate only if email is sent successfully
                } else {
                    console.error("Failed to send email", res);
                    // Optionally, show an error message to the user
                }
            } catch (error) {
                console.error("Error sending email", error);
                // Handle the error (e.g., show a notification)
            }
        }
        setLoader(false);
    };

    return (
        <div id="hero" className="hero-section">
            <div className="">
                <h1>
                    Redefining Healthcare with <br /> our <span>ERP</span>
                </h1>

                <p>
                    Empower your enterprise with Apsthreeai.ai, the ultimate
                    AI-driven solution designed to accelerate efficiency and
                    productivity. <br /> Streamline operations and catalyze
                    business growth with a suite of tools tailored for
                    excellence in management and forecasting.
                </p>
            </div>
            <div className="login-btns-container">
                <button className="erp-demo-btn" onClick={toggleModal}>
                    ERP Demo
                </button>
                <button className="pharmacy-btn">Pharmacy Login</button>
                <button className="admin-btn">Admin Login</button>
                <button className="doctor-btn">Doctor Login</button>
            </div>
            <div className="hero-dashboard-img">
                <img
                    src={`${ERP_CDN_IMAGE_URL}/hero-dashboard.webp`}
                    alt="APS THREEAI LLP"
                    onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                        e.target.src = dashboardImg;
                    }}
                />
            </div>

            {/* Modal for Demo Login */}
            <Modal size="md" centered isOpen={isModalOpen} toggle={toggleModal}>
                <ModalBody
                    style={{
                        height: loader ? "450px" : "",
                    }}
                >
                    {loader ? (
                        <PageLoader />
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IoClose
                                    onClick={toggleModal}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "24px",
                                    }}
                                />
                            </div>
                            <h5
                                style={{
                                    justifyContent: "center",
                                    display: "flex",
                                    textAlign: "center",
                                }}
                            >
                                Share with us your Email and Phone and
                                experience the ERP System
                            </h5>
                            <div className="d-flex justify-content-center align-items-center pb-3">
                                <Form style={{ width: "80%" }}>
                                    <FormGroup className="custom-input-container">
                                        <Label
                                            for="emailId"
                                            className="label-addcustomer"
                                        >
                                            Email ID
                                        </Label>
                                        <Input
                                            type="email"
                                            name="emailId"
                                            id="emailId"
                                            placeholder="Enter Email ID"
                                            value={email}
                                            onChange={handleEmailChange}
                                            maxLength={100}
                                            className={emailValidation} // Add class based on validation
                                        />
                                        {emailValidation === "has-danger" && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                Please enter a valid email
                                                address.
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="custom-input-container">
                                        <Label
                                            for="phoneNumber"
                                            className="label-addcustomer"
                                        >
                                            Phone Number{" "}
                                        </Label>
                                        <PhoneInput
                                            containerClass="phone-input-custom"
                                            buttonClass="flag-search-btn-custom"
                                            dropdownClass="flag-dropdown-custom"
                                            country={"in"}
                                            inputClass={mobileInputValidClass}
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            value={mobile}
                                            enableSearch={true}
                                            placeholder="+91 00000-00000"
                                            prefix="+"
                                            onChange={(e) => {
                                                handleMobileChange(e);
                                            }}
                                            enableLongNumbers={true} // Allows longer phone numbers
                                        />
                                        {mobileValidation === "has-danger" && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                Please enter a valid phone
                                                number.
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="custom-input-container consent-input-container">
                                        <Input
                                            type="checkbox"
                                            name="consent"
                                            id="consent"
                                            onChange={(e) => {
                                                setConsent(e.target.checked);
                                            }}
                                        />
                                        <label htmlFor="consent">
                                            I consent to receive promotional
                                            offers and be contacted about ERP
                                            systems and pricing.
                                        </label>
                                        {consentValidation === "has-danger" && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    marginTop: "5px",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Please accept the terms to
                                                proceed.
                                            </div>
                                        )}
                                    </FormGroup>

                                    <div
                                        className="mt-3"
                                        style={{
                                            justifyContent: "center",
                                            display: "flex",
                                        }}
                                    >
                                        <CustomButton
                                            onClick={handleLogin}
                                            label="Login"
                                            customStyle="w-100"
                                        />
                                    </div>
                                </Form>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default Hero;
