import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import childAnimation from "assets/lottie/child-loader.json";
import Lottie from "lottie-react";

function ChildLoader(props) {
    return (
        // <Col
        //   style={{
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        // >
        //   <Row className="mb-1">
        //     <div>
        //       <Spinner color="primary" type="grow">
        //         Loading...
        //       </Spinner>
        //       <Spinner color="success" type="grow">
        //         Loading...
        //       </Spinner>
        //       <Spinner color="warning" type="grow">
        //         Loading...
        //       </Spinner>
        //     </div>
        //   </Row>
        // </Col>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Lottie
                style={{ width: "200px", height: "200px" }}
                animationData={childAnimation}
                loop={true}
            />
        </div>
    );
}

export default ChildLoader;
