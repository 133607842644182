import React, { useEffect, useRef, useState } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import ProtectedRoute from "./protectedRoute";
import EditBill from "views/CreateBill/EditBill";
import RefundBill from "views/CreateBill/RefundBill";
import EditInventory from "views/Inventory/EditInventory";
import Dashboard from "views/Dashboard/Dashboard";
import { AUTH_URL_LOGIN } from "helpers/Paths";
import { useLoginStore } from "hooks/Login";
import PageLoader from "components/Loader/PageLoader";
import LoginAPICall from "service/LoginAPICall";
import Common from "helpers/Common";
import store from "store/store";
import { logout } from "store/slice/Login";
import { URL_BEST_SELLING_USAGE_REPORT } from "helpers/Paths";
import { URL_LEAST_SELLING_USAGE_REPORT } from "helpers/Paths";
import MaximumStockUsageReport from "views/Report/MaximumStockUsageReport";
import MinimumStockUsageReport from "views/Report/MinimumStockUsageReport";
import { URL_REVENUE_REPORT_LINE_CHART } from "helpers/Paths";
import RevenueReportLineChart from "views/Revenue/RevenueReportLineChart";
import ReorderStockReport from "views/Inventory/ReorderStockReport";
import { URL_REORDER_STOCK_REPORT } from "helpers/Paths";
import { URL_FORECASTING_DEMAND_REPORT } from "helpers/Paths";
import ForecastingAndDemandReport from "views/Report/ForecastingAndDemandReport";
import ForecastingTimeAndDemandReport from "views/Report/ForecastingTimeAndDemandReport";
import { URL_FORECASTING_TIME_DEMAND_REPORT } from "helpers/Paths";
import { SpeedDial } from "primereact/speeddial";
import "primeicons/primeicons.css";
import { Tooltip } from "primereact/tooltip";
import { URL_RAISE_ISSUE } from "helpers/Paths";
import { URL_CONNECT_US } from "helpers/Paths";
import { URL_FEEDBACK } from "helpers/Paths";
import Feedback from "views/Feedback/Feedback";
import RaiseIssue from "views/RaiseIssue/RaiseIssue";
import ConnectUs from "views/ConnectUs/ConnectUs";

var ps;

function Admin(props) {
    const [{}, {}, { validateUserLogin }] = useLoginStore();
    const location = useLocation();
    const navigate = useNavigate();

    const [successSession, setSuccessSession] = useState(null);

    const mainPanel = useRef();
    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanel.current);
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.className += " perfect-scrollbar-off";
                document.documentElement.classList.remove(
                    "perfect-scrollbar-on"
                );
            }
        };
    });
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainPanel.current.scrollTop = 0;
    }, [location]);

    useEffect(() => {
        async function checkValidSession() {
            let res = await validateUserLogin();
            // console.log('validate user login', res)
            setSuccessSession(res);
            if (res === false) {
                new LoginAPICall().companyLogout({
                    loginid: Common.getCompanyId(),
                });
                store.dispatch(logout());
                navigate(AUTH_URL_LOGIN);
            }
        }
        checkValidSession();
    }, []);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.path}
                        element={
                            successSession ? prop.component : <PageLoader />
                        }
                        key={key}
                        exact
                    />
                );
            } else {
                return null;
            }
        });
    };
    const handleActiveClick = (color) => {
        setActiveColor(color);
    };
    const handleBgClick = (color) => {
        setBackgroundColor(color);
    };
    const handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            setSidebarMini(false);
        } else {
            setSidebarMini(true);
        }
        document.body.classList.toggle("sidebar-mini");
    };

    // Speed dial items
    const items = [
        {
            label: "Connect Us",
            icon: "pi pi-whatsapp",
            command: () => {
                navigate(`/admin${URL_CONNECT_US}`);
            },
        },
        {
            label: "Feedback",
            icon: "pi pi-exclamation-triangle",
            command: () => {
                navigate(`/admin${URL_FEEDBACK}`);
            },
        },
        {
            label: "Raise An Issue",
            icon: "pi pi-wrench",
            command: () => {
                navigate(`/admin${URL_RAISE_ISSUE}`);
            },
        },
    ];

    return (
        <div className="wrapper">
            {/* <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      /> */}
            <div className="main-panel" ref={mainPanel}>
                <AdminNavbar
                    {...location}
                    routes={routes}
                    handleMiniClick={handleMiniClick}
                />
                <Routes>
                    <Route exact path="/" element={<ProtectedRoute />}>
                        {getRoutes(routes)}
                        <Route
                            path="/editBill/:id"
                            element={
                                successSession ? <EditBill /> : <PageLoader />
                            }
                        />
                        <Route
                            path="/refundBill/:id"
                            element={
                                successSession ? <RefundBill /> : <PageLoader />
                            }
                        />
                        <Route
                            path="/editInventory/:productName"
                            element={
                                successSession ? (
                                    <EditInventory />
                                ) : (
                                    <PageLoader />
                                )
                            }
                        />
                        <Route
                            path={URL_REVENUE_REPORT_LINE_CHART}
                            element={
                                successSession ? (
                                    <RevenueReportLineChart />
                                ) : (
                                    <PageLoader />
                                )
                            }
                        />
                        <Route
                            path={URL_BEST_SELLING_USAGE_REPORT}
                            element={
                                successSession ? (
                                    <MaximumStockUsageReport />
                                ) : (
                                    <PageLoader />
                                )
                            }
                        />
                        <Route
                            path={URL_LEAST_SELLING_USAGE_REPORT}
                            element={
                                successSession ? (
                                    <MinimumStockUsageReport />
                                ) : (
                                    <PageLoader />
                                )
                            }
                        />
                        <Route
                            path={URL_REORDER_STOCK_REPORT}
                            element={
                                successSession ? (
                                    <ReorderStockReport />
                                ) : (
                                    <PageLoader />
                                )
                            }
                        />
                        <Route
                            path={URL_FORECASTING_DEMAND_REPORT}
                            element={
                                successSession ? (
                                    <ForecastingAndDemandReport />
                                ) : (
                                    <PageLoader />
                                )
                            }
                        />
                        <Route
                            path={URL_FORECASTING_TIME_DEMAND_REPORT}
                            element={
                                successSession ? (
                                    <ForecastingTimeAndDemandReport />
                                ) : (
                                    <PageLoader />
                                )
                            }
                        />
                        <Route
                            path={URL_FEEDBACK}
                            element={
                                successSession ? <Feedback /> : <PageLoader />
                            }
                        />
                        <Route 
                        path={URL_RAISE_ISSUE}
                        element={
                            successSession ? <RaiseIssue /> : <PageLoader />
                        }
                        />
                        <Route
                            path={URL_CONNECT_US}
                            element={
                                successSession ? <ConnectUs /> : <PageLoader />
                            }
                        />
                        <Route
                            path="*"
                            element={
                                successSession ? <Dashboard /> : <PageLoader />
                            }
                        />
                    </Route>
                </Routes>
                {
                    // we don't want the Footer to be rendered on full screen maps page
                    location.pathname.indexOf("full-screen-map") !==
                    -1 ? null : (
                        <Footer fluid />
                    )
                }
            </div>
            <FixedPlugin />

            <SpeedDial
                model={items}
                direction="up-left"
                type="quarter-circle"
                radius={80}
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    zIndex: 99,
                }}
                pt={{
                    button: {
                        root: {
                            style: {
                                width: "50px",
                                height: "50px",
                                backgroundColor: "#434ce6",
                                boxShadow: "none",
                                border: "none",
                            },
                        },
                        icon: {
                            style: {
                                color: "white",
                            },
                        },
                    },
                    action: {
                        style: {
                            width: "50px",
                            height: "50px",
                            boxShadow: "none",
                            border: "none",
                            textDecoration: "none",
                            zIndex: 1040,
                        },
                    },
                    actionIcon: {
                        style: {
                            color: "white",
                        },
                    },
                }}
                className="speeddial-bottom-right"
            />
            <Tooltip
                target=".speeddial-bottom-right .p-speeddial-action"
                position="left"
                pt={{
                    root: {
                        style: {
                            zIndex: 999,
                            fontSize: "14px",
                            fontFamily: "'DM Sans', sans-serif",
                        },
                    },
                }}
            />
        </div>
    );
}

export default Admin;
