import { useEffect, useRef, useState } from "react";
import "../../assets/styles/DownloadReport.css";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import { FaDownload, FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa6";
import * as XLSX from "xlsx";
import { Popover, PopoverBody } from "reactstrap";

const DownloadReport = ({ reportData, headers, headersPDF }) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, [popoverRef]);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(reportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
        XLSX.writeFile(workbook, "report.xlsx");
    };

    const downloadPDF = () => {
        const doc = new jsPDF();

        if (!headersPDF) {
            doc.autoTable({
                head: [headers.map((header) => header.label)],
                body: reportData.map((row) =>
                    headers.map((header) => row[header.key])
                ),
            });
            doc.save("report.pdf");
        } else {
            doc.autoTable({
                head: [headersPDF.map((header) => header.label)],
                body: reportData.map((row) =>
                    headersPDF.map((header) => row[header.key])
                ),
            });
            doc.save("report.pdf");
        }
    };

    const togglePopover = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="download-popover-container">
                <button
                    id="download-popover"
                    type="button"
                    className="download-button"
                    onClick={togglePopover}
                >
                    <FaDownload size={20} color="#fff" />
                </button>

                <Popover
                    placement="auto"
                    isOpen={isOpen}
                    target="download-popover"
                    toggle={togglePopover}
                    className="download-popover"
                >
                    <PopoverBody style={{ textAlign: "center" }}>
                        <div className="header">Download as</div>
                        <div className="">
                            <p className="popover-list" onClick={downloadExcel}>
                                <FaFileExcel size={20} className="mr-2" /> Excel
                            </p>
                            <p className="popover-list">
                                <FaFileCsv size={20} className="mr-2" />
                                <CSVLink
                                    data={reportData}
                                    headers={headers}
                                    filename="report.csv"
                                    className="item-csv"
                                >
                                    CSV
                                </CSVLink>
                            </p>
                            <p className="popover-list" onClick={downloadPDF}>
                                <FaFilePdf size={20} className="mr-2" /> PDF
                            </p>
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        </>
    );
};

export default DownloadReport;
