import { DirectLine } from "botframework-directlinejs";
import React from "react";
import ReactWebChat from "botframework-webchat";
import { Card, CardBody, CardHeader } from "reactstrap";
import { GiMagicHat } from "react-icons/gi";
import { FaWindowMaximize } from "react-icons/fa";
import { MdMaximize } from "react-icons/md";
import { FaSnapchatGhost } from "react-icons/fa";

import { BsMarkdownFill, BsRobot } from "react-icons/bs"; // Import Bootstrap icon
import { IoCloseCircleOutline } from "react-icons/io5";

// Custom renderMarkdown function to format URLs with the icon
const renderMarkdown = (markdownText) => {
    // Regex to find URLs in the text
    const urlRegex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    // Replace URLs with clickable links and the BsMarkdownFill icon
    return markdownText.replace(urlRegex, (url) => {
        return `
      <span style="color: inherit;"> 
        <BsMarkdownFill style="color: #1a0dab; margin-right: 4px;" /> 
        <a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #1a0dab; text-decoration: underline;">${url}</a>
      </span>
    `;
    });
};

export default class extends React.Component {
    constructor(props) {
        super(props);
        // this.handleClickChat2 = this.props.handleClickChat2

        this.directLine = new DirectLine({
            token: "PNSL6r8aYu4.PLP6xvsSn4L2dTkkL6VabOcOu5pTnWWu7XZjbMAUTv8",
        });

        this.styleOptions = {
            rootHeight: "390px",
            innerHeight: "100px",
            // General bubble styling
            bubbleBackground: "#f7f7f7c7", // Soft Blue background for bubbles
            bubbleTextColor: "#000000", // Navy Blue text color

            // User message bubble styling
            bubbleFromUserBackground: "#f7f7f7c7", // Blue background for user messages
            bubbleTextColorUser: "#000000", // White text color for user messages
            bubbleFromUserBorderRadius: "10px",

            // Bot message bubble styling
            bubbleBackgroundColorBot: "#f7f7f7c7", // White background for bot messages
            bubbleTextColorBot: "#000000", // Dark gray text color for bot messages

            // Link styling
            linkColor: "#1a0dab", // Standard blue color for links
            linkHoverColor: "#551A8B", // Color when hovering over links

            // Bubble border styling
            bubbleBorderRadius: "10px", // Rounded corners for bubbles

            // Bubble padding and margin
            bubblePadding: "10px", // Padding inside the bubble
            bubbleMargin: "4px", // Margin between bubbles,

            avatarSize: '30px',
            botAvatarImage: "https://www.pngitem.com/pimgs/m/122-1223088_one-bot-discord-avatar-hd-png-download.png",
            botAvatarInitials: "",
            botAvatarBackgroundColor: "#f7f7f7c7",
            userAvatarBackgroundColor: "#f7f7f7c7",
            userAvatarInitials: "",
        };
    }
    render() {
        const userID = localStorage.getItem("loginId");
        return (
            <>
                <Card className="chatbot-card">
                    <CardHeader>
                        <h5>
                            <BsRobot
                                size={24}
                                style={{ marginRight: "10px" }}
                            />
                            Help Center
                        </h5>
                        <button
                            onClick={() => {
                                this.props.setClasses("dropdown");
                                localStorage.setItem(
                                    "isChatBotActive",
                                    "false"
                                ); // Set chatbot as inactive in local storage
                            }}
                        >
                            <IoCloseCircleOutline size={24} />
                        </button>
                    </CardHeader>
                    <CardBody id="chat">
                        <ReactWebChat
                            styleOptions={this.styleOptions}
                            directLine={this.directLine}
                            userID={this.userID}
                            renderMarkdown={renderMarkdown}
                        />
                    </CardBody>
                </Card>
            </>
        );
    }
}
