import React, { useRef, useState } from "react";

// reactstrap components
import { Button, Card } from "reactstrap";

import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import { usePaymentStore } from "hooks/Payment";
import Common from "helpers/Common";
import moment from "moment";

const RazorPayPayment = (props) => {
  const {
    amount,
    disabled,
    title,
    companyName,
    manageSuccessPayment,
    isCustomPlanPayment,
  } = props;
  const [Razorpay] = useRazorpay();
  const [{}, {}, { razorPayCreateOrder }] = usePaymentStore();

  const handlePayment = useCallback(async () => {
    let param = {
      orderId: Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS"),
      amount: isCustomPlanPayment ? amount.current * 100 : amount * 100,
      currency: "INR",
      referenceId: companyName,
      name: "APS THREEAI LLP",
      email: "admin@apsthreeai.ai/",
      phoneNumber: "(+91) 6356561125",
      address:
        "H-203, Shukan Platinum Appt, Opp. Satyam Hospital, Nr. Vandemataram City, Ahmedabad, Gujarat, India - 382481",
      companyId: Common.getCompanyId(),
      description: "Subscription Payment",
    };

    const order = await razorPayCreateOrder(param);

    if (order?.attributes?.id) {
      const options = {
        // key: param.razorpayKey,
        amount: param.amount,
        currency: param.currency,
        name: param.name,
        description: param.description,
        order_id: order?.attributes?.id,
        handler: (res) => {
          if (
            typeof res.razorpay_payment_id == "undefined" ||
            res.razorpay_payment_id < 1
          ) {
            console.log("fail");
          } else {
            props.manageSuccessPayment();
          }
        },
        prefill: {
          name: "Devanshi Patel",
          email: param.email,
          contact: param.phoneNumber,
        },
        notes: {
          address: param.address,
        },
        theme: {
          color: "#2277DD",
        },
      };
      const rzpay = new Razorpay(options);
      rzpay.open();
    }
  }, [Razorpay]);

  return (
    <Button
      className="button_strat_get"
      disabled={disabled}
      block
      onClick={handlePayment}
    >
      {title}
    </Button>
  );
};

export default RazorPayPayment;
