import { Card, CardBody, Col, Row } from "reactstrap";
import analytics from "assets/img/landing-page/features/analytics.webp";
import inventory_management from "assets/img/landing-page/features/inventory-management.webp";
import invoice_management from "assets/img/landing-page/features/invoice-management.webp";
import multi_device_support from "assets/img/landing-page/features/multi-device-support.webp";
import sales1 from "assets/img/landing-page/features/sales-1.webp";
import sales2 from "assets/img/landing-page/features/sales-2.webp";
import sales3 from "assets/img/landing-page/features/sales-3.webp";
import sales4 from "assets/img/landing-page/features/sales-4.webp";
import schedule_notifications from "assets/img/landing-page/features/schedule-notifications.webp";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import "swiper/css";

const Features = () => {
    return (
        <div id="features" className="features-section">
            <h1>Our Features</h1>

            <Row style={{ rowGap: "40px" }}>
                <Col sm={12} md={6} lg={4}>
                    <Card className="feature-card invoice-management-card">
                        <CardBody className="d-flex flex-column justify-content-between">
                            <div className="feature-details">
                                <h3>Invoice Management</h3>
                                <ul className="feature-list">
                                    <li>Create & Return</li>
                                    <li>Print/Reprint/Storage</li>
                                    <li>GST Compliance</li>
                                    <li>Easy Drafting</li>
                                </ul>
                            </div>
                            <div className="invoice-management-img">
                                <img
                                    src={`${ERP_CDN_IMAGE_URL}/invoice-management.webp`}
                                    alt="Invoice Management"
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                                        e.target.src = invoice_management;
                                    }}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <Card
                        className="feature-card schedule-notifications-card"
                        style={{ padding: "40px 0" }}
                    >
                        <CardBody>
                            <div
                                className="feature-details"
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    zIndex: 2,
                                }}
                            >
                                <p>Seamless Alerts, Right on Time</p>
                                <h3>Schedule Notification</h3>
                            </div>
                            <img
                                src={`${ERP_CDN_IMAGE_URL}/schedule-notifications.webp`}
                                alt="Schedule Notification"
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if logo also fails
                                    e.target.src = schedule_notifications;
                                }}
                                style={{
                                    width: "100%",
                                    aspectRatio: "0.99",
                                    objectFit: "contain",
                                }}
                            />
                            <p
                                style={{
                                    color: "#5B5B5B",
                                    textAlign: "center",
                                }}
                            >
                                Email | SMS | WhatsApp Custom Notifications
                            </p>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <Card className="feature-card">
                        <CardBody className="d-flex flex-column justify-content-between">
                            <div className="feature-details">
                                <h3>
                                    Powerful Analytics at <br /> Your Fingertips
                                </h3>
                            </div>
                            <div className="analytics-img">
                                <img
                                    src={`${ERP_CDN_IMAGE_URL}/analytics.webp`}
                                    alt="Analytics"
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                                        e.target.src = analytics;
                                    }}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <Card className="feature-card">
                        <CardBody>
                            <div className="feature-details d-flex flex-column align-items-center">
                                <h3 className="text-center">
                                    Streamlined <span>Sales</span> <br />{" "}
                                    Operations
                                </h3>
                                <ul className="feature-list">
                                    <li>Sales Tracking</li>
                                    <li>Order History & Repeats</li>
                                    <li>Smart Pricing & Tax</li>
                                    <li>Affordable Subscriptions</li>
                                </ul>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="sales-img-grid">
                                    <div className="">
                                        <img
                                            src={`${ERP_CDN_IMAGE_URL}/sales-1.webp`}
                                            alt="Sales 1"
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevent infinite loop if logo also fails
                                                e.target.src = sales1;
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <img
                                            src={`${ERP_CDN_IMAGE_URL}/sales-2.webp`}
                                            alt="Sales 2"
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevent infinite loop if logo also fails
                                                e.target.src = sales2;
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <img
                                            src={`${ERP_CDN_IMAGE_URL}/sales-3.webp`}
                                            alt="Sales 3"
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevent infinite loop if logo also fails
                                                e.target.src = sales3;
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <img
                                            src={`${ERP_CDN_IMAGE_URL}/sales-4.webp`}
                                            alt="Sales 4"
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevent infinite loop if logo also fails
                                                e.target.src = sales4;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <Card className="feature-card invoice-management-card">
                        <CardBody>
                            <div className="feature-details">
                                <h3>Inventory Management</h3>
                                <ul className="feature-list">
                                    <li>Stock Analysis</li>
                                    <li>GST Support</li>
                                    <li>Reorder Alerts</li>
                                    <li>Discount Management</li>
                                </ul>
                            </div>
                            <div className="inventory-management-img">
                                <img
                                    src={`${ERP_CDN_IMAGE_URL}/inventory-management.webp`}
                                    alt="Inventory Management"
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                                        e.target.src = inventory_management;
                                    }}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <Card className="feature-card">
                        <CardBody className="d-flex flex-column justify-content-between">
                            <div className="feature-details">
                                <h3>
                                    Effortless <br /> Multi-Device Support
                                </h3>
                                <ul className="feature-list multi-device-feature-list">
                                    <li>Cloud-based SaaS for web access</li>
                                    <li>Android & iOS mobile apps</li>
                                    <li>Seamless experience across devices</li>
                                </ul>
                            </div>
                            <div className="multi-device-support-img">
                                <img
                                    src={`${ERP_CDN_IMAGE_URL}/multi-device-support.webp`}
                                    alt="Multi-Device Support"
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop if logo also fails
                                        e.target.src = multi_device_support;
                                    }}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Features;
