import InventoryRepository from "../repository/InventoryRepository";

export function useInventoryStore() {
  /**
   * Here is list of use selectors which are relates to inventory
   * Author: Devanshi Patel
   */

  let data = {};
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to inventory
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */

    getCategoryList: async () => {
      return await new InventoryRepository().getCategoryList();
    },

    getSubCategoryList: async (params) => {
      return await new InventoryRepository().getSubCategoryList(params);
    },

    getCategorySubCategorySearchList: async (params) => {
      return await new InventoryRepository().getCategorySubCategorySearchList(
        params
      );
    },

    inventoryReport: async (params) => {
      return await new InventoryRepository().inventoryReport(params);
    },

    getFileContent: async (params) => {
      return await new InventoryRepository().getFileContent(params);
    },

    uploadedInventorySummary: async (params) => {
      return await new InventoryRepository().uploadedInventorySummary(params);
    },

    uploadedInventorySummaryDetails: async (params) => {
      return await new InventoryRepository().uploadedInventorySummaryDetails(
        params
      );
    },

    getDiscountDetailsByProductId: async (params) => {
      return await new InventoryRepository().getDiscountDetailsByProductId(
        params
      );
    },

    getProductDetailById: async (id, params) => {
      return await new InventoryRepository().getProductDetailsById(id, params);
    },

    getDiscountDetailsByCategoryId: async (params) => {
      return await new InventoryRepository().getDiscountDetailsByCategoryId(
        params
      );
    },

    reorderStockReport: async (params) => {
      return await new InventoryRepository().reorderStockReport(params);
    },

    getProductExpiryDateReport: async (params) => {
      return await new InventoryRepository().getProductExpiryDateReport(params);
    },

    AddNewCategory: async (params) => {
      return await new InventoryRepository().AddNewCategory(
        params
      );
    },

    ModifyCategory: async (params) => {
      return await new InventoryRepository().ModifyCategory(
        params
      );
    },

    AddNewSubCategory: async (params) => {
      return await new InventoryRepository().AddNewSubCategory(
        params
      );
    },

    ModifySubCategory: async (params) => {
      return await new InventoryRepository().ModifySubCategory(
        params
      );
    },
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to inventory
     * Author: Devanshi Patel
     */
    createModifyInventory: async (params) => {
      return await new InventoryRepository().createModifyInventory(params);
    },

    uploadBlobs: async (params) => {
      return await new InventoryRepository().uploadBlobs(params);
    },

    uploadInventoryByFile: async (params) => {
      return await new InventoryRepository().uploadInventoryByFile(params);
    },

    assignInventory: async (params) => {
      return await new InventoryRepository().assignInventory(params);
    },

    createModifyDiscountByCategory: async (params) => {
      return await new InventoryRepository().createModifyDiscountByCategory(
        params
      );
    },

    createModifyDiscountByProduct: async (params) => {
      return await new InventoryRepository().createModifyDiscountByProduct(
        params
      );
    },
  };
  return [data, fetch, set];
}
