import logo from "assets/img/new-logo-with-text-light.svg";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { FaEnvelopeSquare, FaPhoneSquare } from "react-icons/fa";
import {
  FaEnvelope,
  FaGlobe,
  FaLinkedinIn,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa6";
import { Col, Row } from "reactstrap";

const Footer = () => {
    const SOCIAL_MEDIA_LINKS = [
        {
            name: "LinkedIn",
            icon: <FaLinkedinIn />,
            href: "https://www.linkedin.com/company/apsthreeai/",
        },
        {
            name: "Whatsapp",
            icon: <FaWhatsapp />,
            href: "https://wa.me/+916356561125",
        },
        {
            name: "Website",
            icon: <FaGlobe />,
            href: "https://apsthreeai.ai/",
        },
        {
            name: "Phone",
            icon: <FaPhone />,
            href: "tel:+916356561125",
        },
        {
            name: "Gmail",
            icon: <FaEnvelope />,
            href: "mailto:admin@apsthreeai.ai",
        },
    ];

    const scrollToTargetSection = (section) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="footer-container">
            <Row style={{ rowGap: "40px" }}>
                <Col sm={12} md={6} lg={3}>
                    <div className="first-col">
                        <a href="https://apsthreeai.ai/" target="_blank">
                            <img
                                src={`${ERP_CDN_IMAGE_URL}/new-logo-with-text-light.svg`}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if logo also fails
                                    e.target.src = logo;
                                }}
                                alt="APS THREEAI"
                            />
                        </a>

                        <div className="contact-details">
                            <div className=""><FaPhone size={20} /> +91 6356561125</div>
                            <div className=""><FaEnvelope size={20} /> admin@apsthreeai.ai</div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <div className="">
                        <h4>Location</h4>

                        <p>
                            H-203, Shukan Platinum Appt, <br /> Opp. Satyam
                            Hospital, <br />
                            Nr. Vandemataram City, Ahmedabad, <br /> Gujarat,
                            India - 382481
                        </p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <div className="">
                        <h4>Quick Links</h4>

                        <div className="quick-links">
                            <p onClick={() => scrollToTargetSection("hero")}>Home</p>
                            <p onClick={() => scrollToTargetSection("features")}>Features</p>
                            <p onClick={() => scrollToTargetSection("pricing")}>Pricing</p>
                            <p>Privacy Policy</p>
                            <p>Terms & Conditions</p>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={3}>
                    <div className="">
                        <h4>Social Media</h4>

                        <div className="media-links">
                            {SOCIAL_MEDIA_LINKS.map((link) => (
                                <a href={link.href} target="_blank">
                                    {link.icon}
                                </a>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Footer;

{
    /* <a href="https://apsthreeai.ai/" target="_blank">
    <img
        src={`${ERP_CDN_IMAGE_URL}/new-logo-with-text-light.svg`}
        onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if logo also fails
            e.target.src = logo;
        }}
        alt="APS THREEAI"
    />
</a>; */
}
