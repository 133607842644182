import Common from "helpers/Common";
import {
  ADMIN_URL_DASHBOARD,
  AUTH_URL_OTP,
  AUTH_URL_SIGNUP,
} from "helpers/Paths";
import { useLoginStore } from "hooks/Login";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, FormFeedback, FormGroup, Label, Spinner } from "reactstrap";
import { isEmpty, isObject } from "underscore";
import "../../assets/styles/MultiStepRegister.css";
import "../../assets/styles/Stepper.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import success from "assets/img/icons/Auth/success.gif";
import CustomInput from "components/Input/CustomInput";
import { useNotificationStore } from "hooks/Notification";
import moment from "moment";
import { FaUser } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import LoginOTP from "./LoginOTP";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import PageLoader from "components/Loader/PageLoader";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [{ companyDetail }, {}, { companyLogin, setAlerts }] = useLoginStore();
  const [passwordType, setPasswordType] = useState("password");
  const [loginType, setLoginType] = useState("");
  const [loginValidate, setLoginValidate] = useState({});
  const navigate = useNavigate();
  const isLogin = Common.getJwtToken();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpVerify, setOtpVerify] = useState(false);
  const [isOTPsend, setIsOTPsend] = useState(false);
  const [loginTypeInput, setLoginTypeInput] = useState("email");
  const [{}, { sendOTP, verifyOTP, sendOTPGeneral }, {}] =
    useNotificationStore();
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({ replace: true });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [locationInfo, setLocationInfo] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");
  const [countryCodeName, setCountryCodeName] = useState("IN");
  const [countryCode, setCountryCode] = useState("91"); // Default to India country code
  const [isBackClicked, setisBackClicked] = useState(true);

  useEffect(() => {
    if (loginTypeInput == "mobile") {
      const getLocation = async () => {
        try {
          const location = await Common.fetchGeolocation();
          setLocationInfo(location);
        } catch (error) {
          setLocationInfo("Unknown Location");
        }
      };

      getLocation();
    }
  }, [loginTypeInput]);

  useEffect(() => {
    if (loginTypeInput == "mobile") {
      const fetchIPAddress = async () => {
        try {
          const response = await fetch("https://api64.ipify.org?format=json");
          if (!response.ok) {
            throw new Error("Failed to fetch IP address");
          }
          const data = await response.json();
          setIpAddress(data.ip);
        } catch (error) {
          console.error("Error fetching IP address:", error);
        }
      };
      fetchIPAddress();
    }
  }, [loginTypeInput]); // Empty array ensures useEffect runs only once on component mount

  useEffect(() => {
    if (loginTypeInput == "mobile") {
      const fetchDeviceInfo = () => {
        const browserInfo = Common.getBrowserInfo();
        const osInfo = Common.getOSInfo();
        const deviceString = `${browserInfo} (${osInfo})`;
        setDeviceInfo(deviceString);
      };
      fetchDeviceInfo();
    }
  }, [loginTypeInput]);

  useEffect(() => {
    let message = searchParams.get("message");
    if (message) {
      message = Common.decryptQueryParam(message.replace(/ /g, "+"));
      setAlerts({
        type: "danger",
        message: message,
      });
    }

    checkIfAlreadyLoggedIn();
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const checkIfAlreadyLoggedIn = () => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    } else {
      if (!Common.getCommonKey()) {
        const commonKey = Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS");
        localStorage.setItem("commonKey", commonKey);
      }
    }
  };

  useEffect(() => {
    if (
      companyDetail &&
      isObject(companyDetail) &&
      !isEmpty(companyDetail) &&
      companyDetail.type === "success"
    ) {
      navigate(ADMIN_URL_DASHBOARD);
    }
  }, [companyDetail]);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleChangeFormat = () => {
    setLoginTypeInput((prev) => {
      if (prev === "email") {
        return "mobile";
      } else {
        return "email";
      }
    });
  };

  const handlePhoneChange = async (value, data) => {
    setCountryCodeName(data?.countryCode.toUpperCase());
    let error = { ...loginValidate };
    if (!Common.verifyLength(value, 12)) {
      error.mobileNumber = "has-danger";
    } else {
      error.mobileNumber = "has-success";
    }

    setLoginValidate(error);
    setPhoneNumber(value);
  };

  const getOTP = async (number) => {
    if (number) {
      let param = {
        location: locationInfo,
        ipAddress: ipAddress,
        device: deviceInfo,
        mobileNumber: "+" + number,
      };

      try {
        let res = await sendOTPGeneral(param);

        // Handle the case where OTP is not sent and there's a success message (limit reached)
        if (res && res.isOTPSent === false) {
          // Pass the full response back so the calling function can handle the alert
          return res;
        } else {
          return { isOTPSent: true }; // OTP sent successfully
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        return { isOTPSent: false }; // OTP sending failed due to an error
      }
    } else {
      return { isOTPSent: false }; // Invalid phone number case
    }
  };

  const sendOTPHandler = async () => {
    setLoader(true);
    // const phoneNumberParsed = parsePhoneNumberFromString(
    //   phoneNumber,
    //   countryCodeName // "IN" is the default country
    // );
    // if (!phoneNumberParsed || !phoneNumberParsed.isValid()) {
    //   setAlerts({
    //     type: "danger",
    //     message: "Invalid Phone Number",
    //   });
    //   return;
    // }

    if (!phoneNumber) {
      await setAlerts({
        type: "danger",
        message: "Invalid Phone Number",
      });
      setLoader(false);
      return;
    } else {
      let response = await getOTP(phoneNumber);

      if (response.isOTPSent) {
        setLoader(false);
        setIsOTPsend(true);
      } else {
        setLoader(false);
        setIsOTPsend(false);

        // Check if the response contains a success message indicating OTP limit reached
        if (response.success) {
          setAlerts({
            type: "danger",
            message: response.success, // Display the limit reached message from the API
          });
        } else {
          setAlerts({
            type: "danger",
            message: "Please Enter Correct Phone Number",
          });
        }
      }
    }

    // if (phoneNumber.length === 12) {
    //   let isOTPSent = await getOTP(phoneNumber);
    //   if (isOTPSent) {
    //     setIsOTPsend(true);
    //   } else {
    //     setIsOTPsend(false);
    //   }
    // } else {
    //   setAlerts({
    //     type: "danger",
    //     message: "Please Enter Correct Phone Number",
    //   });
    //   return;
    // }
    setLoader(false);
  };

  const handleLogin = async (e) => {
    setLoader(true);
    e.preventDefault();
    let error = false;
    let validate = { ...loginValidate };
    if (loginValidate && isEmpty(loginValidate)) {
      error = true;
      validate.userId = "has-danger";
      validate.password = "has-danger";
    } else {
      if (!loginValidate.userId || loginValidate.userId === "has-danger") {
        error = true;
        validate.userId = "has-danger";
      }
      if (!loginValidate.password || loginValidate.password === "has-danger") {
        error = true;
        validate.otp = "has-danger";
      }
    }
    setLoginValidate(validate);

    if (!userName || !password) {
      await setAlerts({
        type: "danger",
        message: "Please fill all fields",
      });
      setLoader(false);
      return;
    }

    if (captchaVerified === false) {
      await setAlerts({
        type: "danger",
        message: "Please verify captcha",
      });
      setLoader(false);
      return;
    }

    if (!error) {
      let param = {
        loginId: userName,
        loginPassword: password,
      };
      let res = await companyLogin(param);

      // console.log(res);

      if (res?.payload?.type === "fail") {
        setIsSuccess(false);
        setLoader(false);
        await setAlerts({
          type: "danger",
          message: res.payload.message,
        });
      } else {
        setIsSuccess(true);
        setLoader(false);
      }
    }
  };

  function onChangeCaptcha(value) {
    if (value !== null) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  }

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="">
          {loginTypeInput === "email" ? (
            <>
              {isSuccess ? (
                // Success message with GIF
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${ERP_CDN_IMAGE_URL}/success.gif`}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop if logo also fails
                      e.target.src = success;
                    }}
                    alt="Success"
                  />
                </div>
              ) : (
                // Email login form
                <Form
                  className="form login-form-container"
                  onSubmit={handleLogin}
                >
                  <div className="form-group-control-container">
                    <FormGroup className="has-label">
                      <CustomInput
                        placeholder="User Id"
                        type="text"
                        className="form-control"
                        autoFocus={true}
                        onChange={(e) => {
                          let error = { ...loginValidate };
                          if (!Common.verifyLength(e.target.value, 1)) {
                            error.userId = "has-danger";
                          } else {
                            error.userId = "has-success";
                          }
                          setLoginValidate(error);
                          setUserName(e.target.value);
                        }}
                        valid={loginValidate?.userId === "has-success"}
                        invalid={loginValidate?.userId === "has-danger"}
                        maxLength={100}
                        icon={<FaUser />}
                        feedback="User Id is required."
                      />
                    </FormGroup>
                    <FormGroup className="has-label">
                      <CustomInput
                        placeholder="Password"
                        name="password"
                        type={passwordType}
                        autoComplete="off"
                        onChange={(e) => {
                          let error = { ...loginValidate };
                          if (!Common.verifyLength(e.target.value, 5)) {
                            error.password = "has-danger";
                          } else {
                            error.password = "has-success";
                          }
                          setLoginValidate(error);
                          setPassword(e.target.value);
                        }}
                        valid={loginValidate?.password === "has-success"}
                        invalid={loginValidate?.password === "has-danger"}
                        maxLength={15}
                        onEyeClick={togglePassword}
                        passwordType={passwordType}
                        showEyeIcon={true}
                      />
                      <FormFeedback>
                        Password must be 5 characters long.
                      </FormFeedback>
                      <div
                        className="forgot-password-container"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Link style={{ marginTop: "10px" }} to={AUTH_URL_OTP}>
                          Forgot Password?
                        </Link>
                      </div>
                    </FormGroup>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey="6Lcj7NAZAAAAAPTLGuMNtZIuSdxhFFHdgb4LizcG"
                      onChange={onChangeCaptcha}
                    />
                  </div>
                  <div className="d-flex flex-column" style={{ gap: "10px" }}>
                    <button
                      className={
                        captchaVerified
                          ? "custom-login-btn"
                          : "custom-login-btn-disabled"
                      }
                      type="submit"
                      disabled={!captchaVerified}
                    >
                      {loader ? (
                        <Spinner size="sm" color="light" className="mr-2" />
                      ) : null}
                      Login
                    </button>
                    <div
                      className="sign-up-text"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        type="button"
                        className="custom-login-btn secondary-login-btn"
                        style={{ width: "50%", margin: 0 }}
                        onClick={() => navigate(AUTH_URL_SIGNUP)}
                      >
                        Sign up
                      </button>
                      <button
                        type="button"
                        className="custom-login-btn secondary-login-btn"
                        style={{ width: "50%", margin: 0 }}
                        onClick={handleChangeFormat}
                      >
                        Login with Mobile
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </>
          ) : (
            <>
              {isOTPsend ? (
                // OTP sent, show OTP verification form
                <LoginOTP
                  mobileNumber={phoneNumber}
                  otpVerify={otpVerify}
                  setOtpVerify={setOtpVerify}
                  loginValidate={loginValidate}
                  setLoginValidate={setLoginValidate}
                  isSuccess={isSuccess}
                  setIsSuccess={setIsSuccess}
                  setLoader={setLoader}
                  loader={loader}
                  setIsOTPsend={setIsOTPsend}
                  setisBackClicked={setisBackClicked}
                  isBackClicked={isBackClicked}
                />
              ) : (
                // Show mobile login form if the back button was clicked
                <div className="login-form-container">
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    Login with Mobile OTP
                  </h3>
                  <FormGroup className="has-label">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label>Mobile Number *</Label>
                    </div>
                    <PhoneInput
                      containerClass="react-tel-input-custom"
                      buttonClass="flag-search-btn-custom"
                      dropdownClass="flag-dropdown-custom"
                      inputProps={{ autoFocus: true }}
                      country={"in"}
                      placeholder="Enter Mobile Number"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                      disabled={otpVerify ? true : false}
                      enableLongNumbers={true}
                    />
                  </FormGroup>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <button
                      className="custom-login-btn"
                      onClick={sendOTPHandler}
                    >
                      Send OTP
                    </button>
                    <button
                      className="custom-login-btn secondary-login-btn"
                      onClick={() => navigate(AUTH_URL_SIGNUP)}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
export default Login;
