import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Col, Row } from "reactstrap";

const Pricing = () => {
    const PLANS = [
        {
            name: "Free Trial",
            price: 0,
            duration: "For 1 month",
            inventoryCount: 100,
            billCount: 100,
            sms: 100,
        },
        {
            name: "Small Business",
            price: 9439,
            duration: "/ year",
            inventoryCount: "50K",
            billCount: "50K",
            sms: "50K",
        },
        {
            name: "Enterprise",
            price: 17699,
            duration: "/ year",
            inventoryCount: "2L",
            billCount: "2L",
            sms: "2L",
        },
    ];

    return (
        <div id="pricing" className="pricing-section">
            <h1>Pricing Plans</h1>
            <Row style={{ rowGap: "40px" }}>
                {PLANS.map((plan, index) => (
                    <Col key={index} xs={12} md={6} lg={4}>
                        <div className="price-card">
                            <p className="plan-name">{plan.name}</p>
                            <div className="price-text">
                                <p>
                                    <span>₹ {plan.price}</span>&nbsp;
                                    {plan.duration}
                                </p>
                            </div>

                            <div className="features-included">
                                <p>
                                    <b>{plan.inventoryCount}</b> Inventory Count
                                </p>
                                <p>
                                    <b>{plan.billCount}</b> Maximum Invoice /
                                    Bill Count
                                </p>
                                <p>
                                    <b>{plan.sms}</b> SMS, Email, WhatsApp Count
                                </p>
                            </div>

                            <div className="choose-plan-btn">
                                <button>
                                    <p>Choose Plan</p>
                                    <span>
                                        <FaArrowRightLong />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Pricing;
