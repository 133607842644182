import ChildLoader from "components/Loader/ChildLoader";
import Common from "helpers/Common";
import { URL_INVENTORY_PRODUCTS_ADD } from "helpers/Paths";
import { useDashboardV2DataStore } from "hooks/DashboardV2";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col } from "react-bootstrap";
import { FaPenToSquare } from "react-icons/fa6";
import { Link } from "react-router-dom";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import CreateBillRepository from "repository/CreateBillRepository";
import FilterButton from "views/Report/FilterButton";

const RecentProductList = () => {
    const [{}, { getRecentInventory }, {}] = useDashboardV2DataStore();
    
    const [isLoading, setIsLoading] = useState(false);
    const [recentInventoryData, setRecentInventoryData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedDate, setSelectedDate] = useState("");

    const currencySymbol = new CreateBillRepository().getCurrencyAbbriviation();

    function formatDate(date) {
        let inputDate = new Date(date);
        let year = inputDate.getFullYear();
        let month = String(inputDate.getMonth() + 1).padStart(2, "0");
        let day = String(inputDate.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    function getTodayDate() {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }

    const getRecentlyAddedProducts = async (page = 1) => {
        if (page === 1) {
            setPageNumber(1);
        }

        try {
            let RecentInvData = await getRecentInventory({
                params: {
                    fromdate:
                        selectedDate.fromDate || formatDate(getTodayDate()),
                    toDate: selectedDate.toDate || formatDate(getTodayDate()),
                    pagesize: new CompanyProfileRepository().getPageSize(),
                    pageNumber: page,
                },
            });

            if (page > 1) {
                setRecentInventoryData((prev) => [...prev, ...RecentInvData]);
                setIsLoading(false);
            } else {
                setRecentInventoryData(RecentInvData);
                setIsLoading(false);
            }
        } catch (error) {
            // for error handling
            alert(error);
        }

        setIsLoading(false);
    };

    const setFilterDates = (fromDate = "", endDate = "", page = 1) => {
        setSelectedDate({ fromDate: fromDate, toDate: endDate });
    };

    useEffect(() => {
        getRecentlyAddedProducts();
    }, []);

    useEffect(() => {
        if (selectedDate) {
            getRecentlyAddedProducts();
        }
    }, [selectedDate]);

    const ActionTemplate = ({ rowData }) => {
        return (
            <div className="d-flex justify-content-around w-100">
                <Link
                    to={`/admin${URL_INVENTORY_PRODUCTS_ADD}`}
                    state={{ productId: rowData?.productID }}
                >
                    <button type="button" className="actions-btn">
                        <FaPenToSquare className="table-action-icon" />
                    </button>
                </Link>
            </div>
        );
    };

    const loadMoreData = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        setLoadingMore(true);
        getRecentlyAddedProducts(pageNumber + 1);
    };

    return (
        <div className="content-dashboard-inventory">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
                className="title-route-container menu-title-div"
            >
                <h4 className="menu-title-h">Recent Inventory</h4>
                <div className="payment-header-btn-container">
                    <FilterButton
                        getReport={setFilterDates}
                        setLoadingFilter={setIsLoading}
                    />
                </div>
            </div>

            <Card>
                <CardBody>
                    {isLoading ? (
                        <ChildLoader />
                    ) : (
                        <>
                            <DataTable
                                value={recentInventoryData ?? []}
                                dataKey="productID"
                                tableStyle={{ minWidth: "25rem" }}
                                pt={{
                                    root: { className: "table" },
                                }}
                            >
                                <Column
                                    field="productName"
                                    header="Product Name"
                                ></Column>
                                <Column
                                    align="center"
                                    field="branchName"
                                    header="Branch Name"
                                ></Column>
                                <Column
                                    field="quantity"
                                    header="Quantity"
                                    align="center"
                                ></Column>
                                <Column
                                    field="categoryName"
                                    header="Category ID"
                                ></Column>
                                <Column
                                    field="unitPrice"
                                    header={`Unit Price (${currencySymbol})`}
                                    body={(rowData) => {
                                        return Number(rowData.unitPrice).toFixed(2);
                                    }}
                                    align="center"
                                ></Column>
                                {/* <Column field="unitItems" header="Unit Items"></Column> */}
                                <Column
                                    field="upcCode"
                                    header="UPC Code"
                                ></Column>
                                <Column
                                    field="productCreationDate"
                                    header="Created On"
                                    align="center"
                                    body={(rowData) => {
                                        try {
                                            return Common.getFormatedDate(
                                                rowData.productCreationDate,
                                                "DD/MM/YYYY hh:mm:ss A",
                                                true
                                            );
                                        } catch (error) {
                                            console.error(error.message);
                                            return "Invalid Date"; // or any fallback display text
                                        }
                                    }}
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) => (
                                        <ActionTemplate rowData={rowData} />
                                    )}
                                    exportable={false}
                                />
                            </DataTable>
                            <Col className="text-center">
                                {isLoading ? (
                                    <ChildLoader />
                                ) : (
                                    recentInventoryData.length > 0 && (
                                        <Button
                                            color="default"
                                            className="show-more-button"
                                            onClick={() => loadMoreData()}
                                        >
                                            Load More
                                        </Button>
                                    )
                                )}
                            </Col>
                        </>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default RecentProductList;
