/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef, useState } from "react";
// react plugin used to create switch buttons
import ChatBot from "views/HelpCenter/ChatBot";
import { IoChatboxEllipses } from "react-icons/io5";

function FixedPlugin(props) {
    const [classes, setClasses] = React.useState("dropdown");
    const handleClickChat = (e) => {
        // console.log("function called")
        if (classes === "dropdown") {
            setClasses("dropdown show");
            localStorage.setItem("isChatBotActive", "true"); // Set chatbot as active in local storage
        } else {
            setClasses("dropdown");
            localStorage.setItem("isChatBotActive", "false"); // Set chatbot as inactive in local storage
        }
    };

    const op = useRef(null);
    return (
        <div
            className="fixed-plugin"
            style={{ backgroundColor: "rgb(24, 107, 195)", cursor: "pointer" }}
        >
            <div className={classes}>
                <div className="help-center-btn">
                    {/* <IoChatboxEllipses size={32} color="whiteSmoke" /> */}
                    <button onClick={handleClickChat}>Help&nbsp;&nbsp;Center</button>
                </div>
                <div
                    className="dropdown-menu show"
                    style={{
                        width: "650px",
                        padding: "0px",
                        borderRadius: "20px",
                    }}
                >
                    <ChatBot
                        op={op}
                        handleClickChat={handleClickChat}
                        setClasses={setClasses}
                        classes={classes}
                    />
                </div>
            </div>
        </div>
    );
}

export default FixedPlugin;
