import React from "react";
import { Spinner } from "reactstrap";
import loading_animation from "assets/lottie/child-loader.json";
import Lottie from "lottie-react";

function PageLoader(props) {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            {/* <>
        <Spinner color="primary" type="grow">
          Loading...
        </Spinner>
        <Spinner color="success" type="grow">
          Loading...
        </Spinner>
        <Spinner color="warning" type="grow">
          Loading...
        </Spinner>
      </> */}
            <Lottie style={{ width: "400px", height: "400px" }} animationData={loading_animation} loop={true} />
        </div>
    );
}

export default PageLoader;
